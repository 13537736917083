import React from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import locale from 'date-fns/locale/nl'

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

export default function DateForm(props) {
    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <Grid container justify="space-around">
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date (Datum)"
                        format="dd/MM/yyyy"
                        value={props.value}
                        onChange={props.handleChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            'tabIndex': '3'
                        }}
                        autoFocus
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}
