import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SalesChart(props) {
    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>Events{props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <ResponsiveContainer>
                <BarChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <Legend/>
                <Tooltip/>
                <XAxis dataKey="date_string" stroke={theme.palette.text.secondary}/>
                <YAxis stroke={theme.palette.text.secondary} >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Events
                        </Label>
                    </YAxis>
                    {props.data ?
                        <Bar dataKey="count" name="Measurements" data={props.data} fill="#8884d8" label={{ position: 'top' }}/>
                        : ""
                    }
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
