import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {formatDate} from '../helpers';
import {a11yProps, TabPanel} from "./TabPanel";


const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})



export default function SalesTable(props) {
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="Orders" {...a11yProps(0)} />
                <Tab label="Quotations" {...a11yProps(1)} />
                <Tab label="Invoices" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <Table size="small">
                    {props.error ? (<caption>{props.helperText}</caption>) : (<> </>)}
                    <TableHead>
                        <TableRow>
                            <TableCell>Order</TableCell>
                            <TableCell>Order date</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Netto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["orders"] ? props.data["orders"].map((row) => (
                            <TableRow>
                                <TableCell>{row["vorh_num"]}</TableCell>
                                <TableCell>{formatDate(row["vobr_dat_order"])}</TableCell>
                                <TableCell>{row["pdeb_bnaam"]}</TableCell>
                                <TableCell>{row["vorh_oms"]}</TableCell>
                                <TableCell align="right">{formatter.format(row["netto_amount"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Table size="small">
                    {props.error ? (<caption>{props.helperText}</caption>) : (<> </>)}
                    <TableHead>
                        <TableRow>
                            <TableCell>Quotation</TableCell>
                            <TableCell>Quotation date</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Netto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["quotations"] ? props.data["quotations"].map((row) => (
                            <TableRow>
                                <TableCell>{row["vofh_num"]}</TableCell>
                                <TableCell>{formatDate(row["vofh_dat_offerte"])}</TableCell>
                                <TableCell>{row["pdeb_bnaam"]}</TableCell>
                                <TableCell>{row["vofh_oms"]}</TableCell>
                                <TableCell align="right">{formatter.format(row["netto_amount"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Table size="small">
                    {props.error ? (<caption>{props.helperText}</caption>) : (<> </>)}
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice</TableCell>
                            <TableCell>Invoice date</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Description(PO's)</TableCell>
                            <TableCell>Invoice</TableCell>
                            <TableCell>BTW</TableCell>
                            <TableCell>Verwerkt(Paid?)</TableCell>
                            <TableCell>Netto(Invoice minus BTW)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["invoices"] ? props.data["invoices"].map((row) => (
                            <TableRow>
                                <TableCell>{row["vfch_num"]}</TableCell>
                                <TableCell>{formatDate(row["vfch_dat_aanmaak"])}</TableCell>
                                <TableCell>{row["vfch_bnaam_1"]}</TableCell>
                                <TableCell>{row["vorh_nums"]}</TableCell>
                                <TableCell>{formatter.format(row["vfch_bdr_tot_factuur"])}</TableCell>
                                <TableCell>{formatter.format(row["vfch_bdr_tot_btw"])}</TableCell>
                                <TableCell>{row["vfch_status"] ? "True" : "False"}</TableCell>
                                <TableCell>{formatter.format(row["vfch_bdr_gdr_netto"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>
        </React.Fragment>
    );
}
