import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {InputLabel, Select} from "@material-ui/core";
import {flaskAPI} from "../flaskAPI";
import {useAuth} from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function TimeWindowSelector(props) {
    const {setAuthTokens} = useAuth();
    const fromDateInitial = new Date();
    fromDateInitial.setMonth(0);
    fromDateInitial.setDate(1);
    const tillDateInitial = new Date();

    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [tillDate, setTillDate] = useState(tillDateInitial);
    const [interval, setInterval] = useState('week');

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);
    const error = fromDateError || tillDateError;

    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(0);

    const classes = useStyles();

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };
    const handleRadioChange = (event) => {
        setInterval(event.target.value);
    };

    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isValidDate(fromDate) && isValidDate(tillDate)) {
            props.onSubmit({fromDate, tillDate, interval, selectedDevice});
        }
    }

    useEffect(() => {
        flaskAPI.get('/api/devices')
            .then(response => {
                if (response.status === 200) {
                    setDevices(response.data);
                } else {
                    // TODO error message
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" error={fromDateError || tillDateError}
                             className={classes.formControl}>
                    <FormLabel component="legend">Period</FormLabel>
                    <FormGroup>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                error={fromDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="fromDate"
                                label="Date from"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                error={tillDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="tillDate"
                                label="Date till"
                                value={tillDate}
                                onChange={handleTillDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDateMessage="Date should not be in future"
                            />
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Interval</FormLabel>
                    <FormGroup>
                        <RadioGroup aria-label="event interval" name="eventInterval" value={interval}
                                    onChange={handleRadioChange}>
                            <FormControlLabel value="week" control={<Radio/>} label="Week"/>
                            <FormControlLabel value="month" control={<Radio/>} label="Month"/>
                            <FormControlLabel value="quarter" control={<Radio/>} label="Quarter"/>
                            <FormControlLabel value="year" control={<Radio/>} label="Year"/>
                        </RadioGroup>
                    </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Client</FormLabel>
                    <FormGroup>
                        <Select
                            native
                            value={selectedDevice}
                            onChange={(event) => setSelectedDevice(event.target.value)}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option value={0}>all</option>
                            {devices.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.username}
                                </option>
                            ))}

                        </Select>
                    </FormGroup>
                </FormControl>
                <FormGroup>
                    <Button disabled={error} type="submit" variant="outlined" color="primary"
                            className={classes.button}>
                        Submit
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}

