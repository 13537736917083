import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";

export default function ComboBox(props) {
    const {setAuthTokens} = useAuth();
    const [employees, setEmployees] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (!employees.length) {
            flaskAPI.get('/api/employees').then(response => {
                let data = response.data;
                setEmployees(data);

                let selected = data.filter(function (item) {
                    return props.value.indexOf(item["mdwa_num"]) > -1;
                });
                setSelected(selected);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [employees.length, props.setAuthTokens, props.value, setAuthTokens]);

    useEffect(() => {
        let selected = employees.filter(function (item) {
            return props.value.indexOf(item["mdwa_num"]) > -1;
        });
        setSelected(selected);
    }, [employees, props.value]);


    const handleChange = (event, newValue) => {
        props.onChange(newValue.map(function (el) {
            return el.mdwa_num;
        }));
    }


    return (
        <Autocomplete
            multiple
            id="tags-standard"
            options={employees}
            getOptionLabel={(option) => option["mdwa_num"] + " - " + option["mdwa_voornaam"] + " " + option["mdwa_naam"]}
            onChange={handleChange}
            value={selected}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    style={{minWidth: 300}}
                />
            )}
        />
    );
}

