import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from 'moment';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {secondsToHm} from '../helpers';
import WrikeButton from '../components/WrikeButton';


function createWeekHeader() {
    const daysOfWeek = ['Ma', 'Di', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    return (
        <>
            {
                daysOfWeek.map((day) =>
                    <TableCell align="center" style={{minWidth: 20, maxWidth: 20}} padding="none">{day}</TableCell>
                )
            }
        </>
    );
}

function createRow(hours) {
    let data = [];
    weekDayLoop: for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 7; j++) {
            if (hours[j] && hours[j]["week_day"] === i) {
                let color;
                if (hours[j].uren_man < (1)) {
                    color = '';
                } else if (hours[j].uren_man <= (3600 * 9)) {
                    color = '#b2ff59';
                } else if (hours[j].uren_man <= (3600 * 10)) {
                    color = '#ffc107';
                } else {
                    color = '#f44336';
                }
                data.push(<TableCell align="center" padding="none" style={{
                    minWidth: 20,
                    maxWidth: 20,
                    margin: 0,
                    backgroundColor: color
                }}>{secondsToHm(hours[j].uren_man)}</TableCell>)
                continue weekDayLoop;
            }
        }
        if (i > 4) {
            data.push(<TableCell align="center" padding="none" style={{
                minWidth: 20,
                maxWidth: 20,
                color: 'black',
                margin: 0
            }}>{secondsToHm(0)}</TableCell>)
        } else {
            data.push(<TableCell align="center" padding="none"
                                 style={{minWidth: 20, maxWidth: 20, margin: 0}}>{secondsToHm(0)}</TableCell>)
        }
    }
    return (<>{data}</>);
}

export default function Employees() {
    const {setAuthTokens} = useAuth();
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [momentDate, setMomentDate] = useState(moment(new Date()).add(-1, 'week'))
    const [rerender, setRerender] = useState(0)

    const addWeek = (n) => {
        setMomentDate(momentDate.add(n, 'week'));
        setRerender(rerender + 1);
    }

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/timesheet/employee-overview/' + momentDate.year() + '/' + momentDate.isoWeek()) // TODO props.date should already be in proper format
            .then(response => {
                setEmployees(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [rerender, momentDate, setAuthTokens]);
    return (
        <React.Fragment>
            <Title>Employees {loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell align="center" colSpan={7}>
                            <IconButton onClick={() => addWeek(-1)}>
                                <ArrowLeftIcon/>
                            </IconButton>
                            Week {momentDate.isoWeek()}
                            <IconButton onClick={() => addWeek(1)}>
                                <ArrowRightIcon/>
                            </IconButton>
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        {createWeekHeader()}
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees ? employees.map((row) => (
                        <TableRow>
                            <TableCell>{row["mdwa_num"]}</TableCell>
                            <TableCell>{row["mdwa_voornaam"] + ' ' + row["mdwa_naam"]}</TableCell>
                            {createRow(row["hours"])}
                            <TableCell>
                                <WrikeButton weekNumber={momentDate.isoWeek()} firstName={row["mdwa_voornaam"]}
                                             lastName={row["mdwa_naam"]}/>
                            </TableCell>
                        </TableRow>
                    )) : (<> </>)}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
