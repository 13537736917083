import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})
const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function Timesheet(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [state, setState] = useState({
        checkedPosStock: true,
        checkedNegStock: true,
    });

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const filteredData = (state.checkedPosStock && state.checkedNegStock) ? data :
        state.checkedPosStock ? data.filter(d => d["part_vrd_aantal"] > 0) :
            state.checkedNegStock ? data.filter(d => d["part_vrd_aantal"] < 0) :
                data.filter(d => d["part_vrd_aantal"] === 0)

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/parts')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const columns = [
        {
            title: 'Code',
            field: 'part_code',
        },
        {
            title: 'Zoeknaam',
            field: 'part_zoeknaam',
        },
        {
            title: 'Tekn. nr',
            field: 'part_teknr',
        },
        {
            title: 'Oms 1',
            field: 'part_oms_1',
        },
        {
            title: 'Oms 2',
            field: 'part_oms_2',
        },
        {
            title: 'Oms 3',
            field: 'part_oms_3',
        },
        {
            title: 'Datum aanmaak',
            field: 'part_dat_aanmaak',
            type: 'date'
        },
        {
            title: 'Datum wijzig',
            field: 'part_dat_wijzig',
            type: 'date'
        },
        {
            title: 'Calc. prijs',
            field: 'part_calc_prijs',
            render: rowData => formatter.format(rowData["part_calc_prijs"]),
        },
        {
            title: 'GV prijs',
            field: 'part_gv_prijs',
            render: rowData => formatter.format(rowData["part_gv_prijs"]),
        },
        {
            title: 'Kostprijs',
            field: 'part_kostprijs',
            render: rowData => formatter.format(rowData["part_kostprijs"]),
        },
        {
            title: 'Verkoop prijs',
            field: 'part_vrk_prijs',
            render: rowData => formatter.format(rowData["part_vrk_prijs"]),
        },
        {
            title: 'Voorraad aantal',
            field: 'part_vrd_aantal',
        },

    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Parts
                        </Typography>

                        <FormGroup row>
                            <FormControlLabel
                                control={<Switch checked={state.checkedNegStock} onChange={handleChange}
                                                 name="checkedNegStock"/>}
                                label="Voorraad < 0"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.checkedPosStock}
                                        onChange={handleChange}
                                        name="checkedPosStock"
                                    />
                                }
                                label="Voorraad > 0"
                            />
                        </FormGroup>
                    </div>
                }
                columns={columns}
                data={filteredData}
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                }}
            />
        </>
    );
}

export default withStyles(styles)(Timesheet);
