import React, {useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import {AuthContext} from "./context/auth";
import {config} from './Constants';
import Cookie from 'js-cookie'
import * as jwtDecode from 'jwt-decode';

function App() {
    const existingTokens = Cookie.get('token');
    const [authTokens, setAuthTokens] = useState(existingTokens);
    let roles = '';

    try {
        roles = authTokens ? Cookie.get('token') ? jwtDecode(Cookie.get('token')).rls : '' : '';
    } catch (error) {

    }


    const setTokens = (data) => {
        Cookie.set('token', data,
            {expires: data ? 14 : 0, secure: config.useSecureCookie,} // 14 days
        );
        setAuthTokens(data);
    }

    return (
        <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens, roles}}>
            <Router>
                <div>
                    <Switch>
                        <Route path="/login" component={Login}/>
                        <PrivateRoute path="/" component={Dashboard}/>
                    </Switch>
                </div>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;