import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography';
import {truncateString} from '../helpers';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130
    }
});

function PurchaseSubscribeTable(props) {
    const {classes} = props;

    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [list, setList] = useState([]);
    const [bookmarkedOnly, setBookmarkedOnly] = useState(false);

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/purchase/list')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/purchase/subs')
            .then(response => {
                if (response.status === 200) {
                    setList(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const handleSubscribe = (rowData) => {
        return new Promise(() => {
            setTimeout(() => {
                if (rowData) {
                    if (list.includes(rowData["ikwh_num"])) {
                        flaskAPI.delete('/api/purchase/subscribe/' + rowData["ikwh_num"]).then(response => {
                            if (response.status === 200) {
                                setList(list.filter(item => item !== rowData["ikwh_num"]))
                                //resolve();
                            } else {
                                //reject();
                            }
                        }).catch(error => {
                            //reject();
                            if (error && error.response && error.response.status === 401) {
                                setAuthTokens();
                            }
                        })
                    } else {
                        flaskAPI.put('/api/purchase/subscribe/' + rowData["ikwh_num"]).then(response => {
                            if (response.status === 200) {
                                setList([...list, rowData["ikwh_num"]]);
                                //resolve();
                            } else {
                                //reject();
                            }
                        }).catch(error => {
                            //reject();
                            if (error && error.response && error.response.status === 401) {
                                setAuthTokens();
                            }
                        })
                    }
                }
            }, 400)
        });
    }

    const columns = [
        {
            title: 'Inkoop order',
            field: 'ikwh_num',
        },
        {
            title: 'Crediteur',
            field: 'pcrd_bnaam',
            render: rowData => truncateString(rowData["pcrd_bnaam"], 25),
            cellStyle: {
                width: 200,
                maxWidth: 200
            }
        },
        {
            title: 'Omschrijving',
            field: 'ikwh_oms_1',
            render: rowData => truncateString(rowData["ikwh_oms_1"], 20),
        },
        {
            title: 'Aanmaak',
            field: 'ikwh_dat_aanmaak',
            type: 'date',
        },
        {
            title: 'Order',
            field: 'ikwh_dat_order',
            type: 'date',
        },
        {
            title: 'Levering',
            field: 'ikwh_dat_lev',
            type: 'date',
        },
    ];

    const filteredData = bookmarkedOnly ? data.filter(rowData => list.includes(rowData["ikwh_num"])) : data;
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Orders
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={bookmarkedOnly}
                                             onChange={() => setBookmarkedOnly(!bookmarkedOnly)}
                                             name="checkedNegStock"/>}
                            label="Show subscribed only"
                        />
                    </div>
                }
                columns={columns}
                data={filteredData}
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                    rows: 8,
                }}
                actions={[
                    rowData => ({
                        icon: () => list.includes(rowData["ikwh_num"]) ? <BookmarkIcon/> : <BookmarkBorderIcon/>,
                        tooltip: 'Subscribe',
                        onClick: (event, rowData) => handleSubscribe(rowData),
                    }),
                ]}
            />
        </>
    );
}

export default withStyles(styles)(PurchaseSubscribeTable);
