import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import {tableIcons} from "../styles";
import {secondsToHm} from "../helpers";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function PoHoursTable(props) {
    const {classes} = props;

    const columns = [
        {
            title: 'Date',
            field: 'uren_datum',
            type: 'date',
        },
        {
            title: 'Employee',
            field: 'mdwa_zoeknaam',
        },
        {
            title: 'Operation',
            field: 'torb_oms',
        },
        {
            title: 'Hours',
            field: 'uren_man',
            render: rowData => secondsToHm(rowData.uren_man),
            type: 'time',
        },
        {
            title: 'Memo',
            field: 'uren_memo',
        },
    ];

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title={props.title}
                columns={columns}
                data={props.data}
                options={{
                    exportAllData: true,
                    paging: true,
                    search: true,
                    sorting: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    pageSize: 20,
                    pageSizeOptions: [20,100],
                }}
            />
        </>
    );
}

export default withStyles(styles)(PoHoursTable);
