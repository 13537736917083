import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {formatDate} from '../helpers';
import {a11yProps, TabPanel} from "./TabPanel";


const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})


export default function Orders(props) {
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="DEBITEUREN" {...a11yProps(0)} />
                <Tab label="CREDITEUREN" {...a11yProps(1)} />
                <Tab label="TERMIJNEN NOG TE FAC." {...a11yProps(2)} />
                <Tab label="EINDFACT. NOG TE FAC." {...a11yProps(3)} />
                <Tab label="CRED. NOG TE FAC." {...a11yProps(4)} />
                <Tab label="Overige kosten" {...a11yProps(5)} />
            </Tabs>

            {/* DEBITEUREN */}
            <TabPanel value={tabValue} index={0}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Factuur nr</TableCell>
                            <TableCell>Klant</TableCell>
                            <TableCell>Factuurdatum</TableCell>
                            <TableCell>Vervaldatum</TableCell>
                            <TableCell>Openstaand bedrag incl BTW</TableCell>
                            <TableCell>Aanmaningen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["deb"] ? props.data["deb"].map((row) => (
                            <TableRow>
                                <TableCell>{row["popd_boekstuk"]}</TableCell>
                                <TableCell>{row["popd_oms"]}</TableCell>
                                <TableCell>{formatDate(row["popd_dat_fac"])}</TableCell>
                                <TableCell>{formatDate(row["popd_dat_verval"])}</TableCell>
                                <TableCell>{formatter.format(row.sign * row["popd_bedrag_open"])}</TableCell>
                                <TableCell>{row["popd_aantal_man"]}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

            {/* CREDITEUREN */}
            <TabPanel value={tabValue} index={1}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Factuur nr</TableCell>
                            <TableCell>Leverancier</TableCell>
                            <TableCell>Factuurdatum</TableCell>
                            <TableCell>Vervaldatum</TableCell>
                            <TableCell>Openstaand bedrag incl BTW</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["cred"] ? props.data["cred"].map((row) => (
                            <TableRow>
                                <TableCell>{row["popc_boekstuk"]}</TableCell>
                                <TableCell>{row["popc_oms"]}</TableCell>
                                <TableCell>{formatDate(row["popc_dat_fac"])}</TableCell>
                                <TableCell>{formatDate(row["popc_dat_verval"])}</TableCell>
                                <TableCell>{formatter.format(row.sign * row["popc_bedrag_open"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

            {/* TERMIJNEN NOG TE FAC. */}
            <TabPanel value={tabValue} index={2}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>VK nr</TableCell>
                            <TableCell>Klant</TableCell>
                            <TableCell>Ref.</TableCell>
                            <TableCell>(Verwachte) Factuurdatum</TableCell>
                            <TableCell>Vervaldatum</TableCell>
                            <TableCell>Openstaand bedrag exl BTW</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["order_term"] ? props.data["order_term"].map((row) => (
                            <TableRow>
                                <TableCell>{row["vorh_num"]}</TableCell>
                                <TableCell>{row["pdeb_bnaam"]}</TableCell>
                                <TableCell>{row["vorh_ref_onze"]}</TableCell>
                                <TableCell>{formatDate(row["vtrm_datum"])}</TableCell>
                                <TableCell>{formatDate(row["date"])}</TableCell>
                                <TableCell>{formatter.format(row["netto_amount_order_term"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

            {/* EINDFACT. NOG TE FAC. */}
            <TabPanel value={tabValue} index={3}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>VK nr</TableCell>
                            <TableCell>Klant</TableCell>
                            <TableCell>Ref.</TableCell>
                            <TableCell>(Verwachte) Factuurdatum</TableCell>
                            <TableCell>Vervaldatum</TableCell>
                            <TableCell>Openstaand bedrag exl BTW</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["order_end"] ? props.data["order_end"].map((row) => (
                            <TableRow>
                                <TableCell>{row["vorh_num"]}</TableCell>
                                <TableCell>{row["pdeb_bnaam"]}</TableCell>
                                <TableCell>{row["vorh_ref_onze"]}</TableCell>
                                <TableCell>{formatDate(row["vobr_dat_planning"])}</TableCell>
                                <TableCell>{formatDate(row["date"])}</TableCell>
                                <TableCell>{formatter.format(row["netto_amount_order_end"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

            {/* CRED. NOG TE FAC. */}
            <TabPanel value={tabValue} index={4}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>IK nr</TableCell>
                            <TableCell>Leverancier</TableCell>
                            <TableCell>(Verwachte) Factuurdatum</TableCell>
                            <TableCell>Vervaldatum</TableCell>
                            <TableCell>Openstaand bedrag exl BTW</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["purch"] ? props.data["purch"].map((row) => (
                            <TableRow>
                                <TableCell>{row["ikwh_num"]}</TableCell>
                                <TableCell>{row["pcrd"]["pcrd_bnaam"]}</TableCell>
                                <TableCell>{formatDate(row["ikwr_dat_lev"])}</TableCell>
                                <TableCell>{formatDate(row["date"])}</TableCell>
                                <TableCell>{formatter.format(row["ikwr_prijs_netto_lv"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

            {/* Overige kosten */}
            <TabPanel value={tabValue} index={5}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Betalingsdatum</TableCell>
                            <TableCell>Openstaand bedrag exl BTW</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.data ? "" : props.data["other_costs"] ? props.data["other_costs"].map((row) => (
                            <TableRow>
                                <TableCell>{formatDate(row["date"])}</TableCell>
                                <TableCell>{formatter.format(row["other_cost"])}</TableCell>
                            </TableRow>
                        )) : (<> </>)}
                    </TableBody>
                </Table>
            </TabPanel>

        </React.Fragment>
    );
}
