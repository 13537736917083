import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function RentalsTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/pohw')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const columns = [
        {
            title: 'PO Number',
            field: 'torh_num',
        },
        {
            title: 'Uw ref.',
            field: 'torh_ref_uw',
        },
        {
            title: 'Onze ref.',
            field: 'torh_ref_onze',
        },
        {
            title: 'Code',
            field: 'part_code',
        },
        {
            title: 'Omschrijving',
            field: 'part_oms_1',
        },
        {
            title: 'Tekn. nr',
            field: 'part_teknr',
        },
        {
            title: 'Klant',
            field: 'pdeb_bnaam',
        },
        {
            title: 'Order datum',
            field: 'torh_dat_order',
        },
        {
            title: 'Plan datum',
            field: 'torh_dat_plan',
        },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Rentals
                        </Typography>
                    </div>
                }
                columns={columns}
                data={data}
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                    padding: 'dense',
                    pageSize: 10,
                    pageSizeOptions: [10, 25]
                }}
            />
        </>
    );
}

export default withStyles(styles)(RentalsTable);
