import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    notation: "compact",
    compactDisplay: "short"
})
export default function SalesChart(props) {
    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>Orders & Offers {props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <ResponsiveContainer>
                <BarChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                    <Legend/>
                    <Tooltip/>
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary}/>
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={tick => {
                               return formatter.format(tick);
                           }}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Sales (€)
                        </Label>
                    </YAxis>
                    {(props.salesType && props.salesType.showNettoOrders) ?
                        <Bar dataKey="netto_amount_orders" name="Netto orders" fill={theme.palette.primary.main}/> :
                        ""}

                    {(props.salesType && props.salesType.showNettoQuotations) ?
                        <Bar dataKey="netto_amount_quotations" name="Netto quotations"
                             fill={theme.palette.secondary.main}/> :
                        ""}

                    {(props.salesType && props.salesType.showNettoInvoices) ?
                        <Bar dataKey="netto_amount_invoices" name="Netto invoices"
                             fill={theme.palette.warning.light}/> :
                        ""}


                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
