import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});
export default function SalesSum(props) {
    const extrap = (val, from, till) => {
        let days = Math.round((till - from) / (1000 * 60 * 60 * 24));
        return val / days * 365;
    }

    const classes = useStyles();
    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    return (
        <React.Fragment>
            <Title>Netto orders {props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {formatter.format((props.data && props.data["total_netto_orders"]) ? props.data["total_netto_orders"] : 0)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                Proj. {formatter.format((props.data && props.data["total_netto_orders"]) ? extrap(props.data["total_netto_orders"], props.from, props.till) : 0)} p/y
            </Typography>

            <Title>Netto quotations {props.loading ? (
                <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {formatter.format((props.data && props.data["total_netto_quotations"]) ? props.data["total_netto_quotations"] : 0)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                Proj. {formatter.format((props.data && props.data["total_netto_quotations"]) ? extrap(props.data["total_netto_quotations"], props.from, props.till) : 0)} p/y
            </Typography>

            <Title>Netto invoiced {props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {formatter.format((props.data && props.data["total_netto_invoices"]) ? props.data["total_netto_invoices"] : 0)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                Proj. {formatter.format((props.data && props.data["total_netto_invoices"]) ? extrap(props.data["total_netto_invoices"], props.from, props.till) : 0)} p/y
            </Typography>
        </React.Fragment>
    );
}
