import React from 'react';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function HourForm(props) {
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <TextField
                    error={props.error}
                    id="standard-error-helper-text"
                    label="Hours"
                    helperText="Notation: 5,5 or 5.5 or 5:30"
                    onChange={props.handleChange}
                    value={props.value}
                    autoFocus
                />
                (Result: {props.error ? "incorrect" : ((props.parsedValue / 3600).toFixed(2) + " hours")})
            </Grid>
        </React.Fragment>
    );
}
