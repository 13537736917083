import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Timesheet from '../components/Timesheet';
import EmployeeInfo from '../components/EmployeeInfo';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function TimesheetPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [infoData, setInfoData] = useState();
    const [weekData, setWeekData] = useState();
    const [weekNumber, setWeekNumber] = useState(moment(new Date()).isoWeek());

    useEffect(() => {
        flaskAPI.get('/api/employee-info')
            .then(response => {
                if (response.status === 200 && Array.isArray(response.data)) {
                    setInfoData(response.data[0]);
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    useEffect(() => {
        let momentDate = moment().isoWeekday(1).isoWeek(weekNumber);
        flaskAPI.get('/api/timesheet/week-overview/' + momentDate.year() + '/' + momentDate.isoWeek())
            .then(response => {
                setWeekData(response.data);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
                setWeekData([]);
            });
    }, [setAuthTokens, weekNumber]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <EmployeeInfo infoData={infoData} weekData={weekData}/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Timesheet onWeekNumberChange={(newWeekNumber) => setWeekNumber(newWeekNumber)}/>
            </Grid>
        </Grid>
    )
}
