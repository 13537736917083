import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import IKPOTable from "../components/IKPOTable";
import IKVKTable from "../components/IKVKTable";
import {a11yProps, TabPanel} from "../components/TabPanel";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});
const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

function PurchaseConflictPage(props) {
    const {classes} = props;
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Paper className={classes.paper}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                    <Tab label="IK vs PO" {...a11yProps(0)} />
                    <Tab label="IK vs VK" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <IKPOTable/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <IKVKTable/>
                </TabPanel>
            </Paper>
        </>
    );
}

export default withStyles(styles)(PurchaseConflictPage);
