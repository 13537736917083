import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import TimeWindowSelector from '../components/TimeWindowSelectorInternExtern';
import GroupsTable from '../components/GroupsTable';
import InternExternTable from '../components/InternExternTable';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));

export default function InternExternPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState();
    const [groupsChanged, setGroupsChanged] = useState(0);

    useEffect(() => {
        if (dates) {
            setData([]);
            setLoading(true);

            flaskAPI.get('/api/totals-kpi/groups/hours', {
                params: {
                    'date_from': dates["fromDate"],
                    'date_till': dates["tillDate"],
                    'interval': '',
                }
            }).then(response => {
                setData(response.data);
                setLoading(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [dates, groupsChanged, setAuthTokens]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Grid container direction="column" spacing={3} justify="center">
                    {/* Time Window */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TimeWindowSelector onDateChanged={(datesVal) => {
                                setDates(datesVal);
                            }
                            }/>
                        </Paper>
                    </Grid>
                    {/* Group */}
                    <Grid item>
                        <GroupsTable onGroupsChanged={() => setGroupsChanged(groupsChanged + 1)}/>
                    </Grid>
                </Grid>
            </Grid>
            {/* Table */}
            <Grid item xs={9}>
                <InternExternTable loading={loading} data={data}/>
            </Grid>
        </Grid>
    );
}
