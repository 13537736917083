import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Employees from '../components/Employees';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));

export default function AdministrationPage() {
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            {/* Hour rows */}
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{overflow: 'hidden'}}>
                    <Employees/>
                </Paper>
            </Grid>
        </Grid>
    )
}

