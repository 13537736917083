import React, {useEffect, useState} from 'react';
import {TextField,} from "@material-ui/core";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import locale from 'date-fns/locale/nl'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {nl2Space} from "../helpers";

function DateSelect(props) {
    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <KeyboardDatePicker
                    style={{width: 150}}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={props.value}
                    onChange={props.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                        'tabIndex': '3'
                    }}
                    autoFocus
                />
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}

function HourInput(props) {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        let newHours = event.target.value;
        props.onChange(newHours)
        if (newHours.match("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")) {
            setError(false);
        } else if (newHours.match("^[0-9]*[.,]?[0-9]*$")) {
            setError(false);
        } else {
            setError(true);
        }
    }

    return (
        <TextField
            error={error}
            id="standard-error-helper-text"
            helperText="e.g. 5,5 or 5.5 or 5:30"
            onChange={handleChange}
            defaultValue={props.value}
            autoFocus
        />
    );
}

function MemoInput(props) {
    const [error, setError] = useState(!props.value || props.value == "");
    const handleChange = (event) => {
        let newVal = event.target.value;
        props.onChange(newVal)
        if(newVal && newVal != "") {
            setError(false);
        } else {
            setError(true);
        }
    }

    return (
        <TextField
            error={error}
            id="standard-error-helper-text"
            onChange={handleChange}
            defaultValue={props.value}
        />
    );
}

function OperationSelect(props) {
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        if (!props.poNum || !loading) {
            return undefined;
        }

        let active = true;
        flaskAPI.get('/api/pos/' + props.poNum + '/ops')
            .then(response => {
                if (response.status === 200) {
                    if (active) {
                        setOptions(response.data);
                    }
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
        return () => {
            active = false;
        };
    }, [setAuthTokens, loading, props.poNum]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            disableClearable
            disabled={!props.poNum}
            style={{width: 150}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.pbwh_num === value.pbwh_num && option.pbws_num === value.pbws_num}
            getOptionLabel={(option) => option.torb_oms}
            onChange={props.onChange}
            options={options}
            loading={loading}
            defaultValue={(props.value && props.value.torb_oms) ? props.value : {
                pbwh_num: 0,
                pbws_num: 0,
                torb_oms: ''
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    defaultValue=""
                    label=""
                    //variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

function PoSelect(props) {
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;
        flaskAPI.get('/api/pos/timesheet')
            .then(response => {
                if (response.status === 200) {
                    if (active) {
                        setOptions(response.data);
                    }
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
        return () => {
            active = false;
        };
    }, [setAuthTokens, loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            disableClearable
            style={{width: 250}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.torh_num === value.torh_num}
            getOptionLabel={(option) => option.torh_num + ' ' + nl2Space(option.torh_ref_onze) + ' ' + option.part_code + ' ' + nl2Space(option.pdeb_bnaam)}
            onChange={props.onChange}
            options={options}
            loading={loading}
            defaultValue={(props.value && props.value.torh_num) ? props.value : {
                torh_num: '',
                torh_ref_onze: '',
                part_code: '',
                pdeb_bnaam: ''
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    defaultValue=""
                    label=""
                    //variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export {DateSelect, PoSelect, OperationSelect, HourInput, MemoInput}
