import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function MemoForm(props) {
    const handleChange = (event) => {
        props.handleChange(event.target.value);
    };

    return (
        <React.Fragment>
            <TextField
                id="outlined-multiline-static"
                label="Memo"
                multiline
                rows={4}
                variant="outlined"
                value={props.value}
                onChange={handleChange}
                autoFocus
            />
        </React.Fragment>
    );
}
