import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withRouter} from 'react-router-dom'

function MyButton(props) {
    function handleClick() {
        props.history.push(props.url);
        props.handleClick(props.label)
    }

    return (
        <ListItem button style={props.nested ? {paddingLeft: 30,} : {}} disabled={props.disabled} onClick={handleClick}>
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.label}/>
        </ListItem>
    );
}


const NavButton = withRouter(MyButton);
export {NavButton};
