import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import AddGroupDialog from './AddGroupDialog';
import GroupComboBox from './GroupComboBox';
import {tableIcons} from "../styles";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    }
});
const columns = [
    {
        title: 'Group',
        field: 'groupname',
    },
    {
        title: 'Members',
        field: 'members',
        render: rowData => Array.isArray(rowData.members) ? rowData.members.length : 0,
        numeric: true,
        editComponent: props => <GroupComboBox value={props.value} onChange={newValue => props.onChange(newValue)}/>
    },
];


function GroupsTable(props) {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/totals-kpi/groups')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.delete('/api/totals-kpi/groups/' + oldData.id).then(response => {
                        if (response.status === 200) {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setData([...dataDelete]);
                            props.onGroupsChanged();
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                }
            }, 400)
        });
    }

    const handleUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.put('/api/totals-kpi/groups/' + oldData.id, newData).then(response => {
                        if (response.status === 200) {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);
                            props.onGroupsChanged();
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 400)
        });
    }

    const handleCreate = (newData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (newData) {
                    flaskAPI.post('/api/totals-kpi/groups', newData).then(response => {
                        if (response.status === 201) {
                            setData([...data, response.data]);
                            props.onGroupsChanged();
                            resolve();
                        } else {
                            reject(response.data);
                        }
                    }).catch(error => {
                        reject(error);
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 400)
        });
    }

    return (
        <>
            <AddGroupDialog open={open} onCreate={handleCreate} onClose={() => setOpen(false)}/>
            <MaterialTable
                localization={{
                    header: {
                        actions: ''
                    }
                }}
                icons={tableIcons}
                isLoading={props.loading || loading}
                title="Groups"
                columns={columns}
                data={data}
                options={{
                    sorting: false,
                    search: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                    paging: false,
                    showTitle: false,
                }}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: 'Add hours',
                        isFreeAction: true,
                        onClick: () => setOpen(true),
                        position: 'header',
                    },
                ]}
                editable={{
                    onRowDelete: handleDelete,
                    onRowUpdate: handleUpdate,
                }}
            />
        </>
    );
}

export default withStyles(styles)(GroupsTable);
