export const truncateString = (str, num) => {
    str = str.replace(/\n/g, " ");
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}

//Replace new-line by a space
export const nl2Space = (str) => {
    return str ? str.replace(/\n/g, " ") : "";
}

export const truncateText = (str, n, useWordBoundary) => {
    if (str.length <= n) {
        return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "...";
}


export const secondsToHm = (d) => {
    if (d === undefined) {
        return undefined;
    }
    d = Number(d);
    let prefix = d < 0 ? "-" : "";
    d = Math.abs(d);
    let h = Math.floor(d / 3600);
    let m = Math.abs(Math.floor(d % 3600 / 60));

    let hDisplay = (h < 10 && h >= 0) ? "0" + h : h;
    let mDisplay = (m < 10 && m >= 0) ? "0" + m : m;

    return prefix + hDisplay + ":" + mDisplay;
}

export function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function parseHourString(val) {
    let seconds;
    if (val.includes(':')) {
        let split = val.split(':');
        seconds = Number(split[0]) * 3600 + Number(split[1]) * 60;
    } else if (val.includes('.') || val.includes(',')) {
        seconds = Number(val.replace(',', '.')) * 3600;
    } else {
        seconds = Number(val * 3600);
    }
    return seconds;
}

