import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ClientForm(props) {
    return (
        <React.Fragment>
            <Autocomplete
                id="combo-box-demo"
                value={props.value}
                onChange={props.handleChange}
                options={props.clientList}
                filterSelectedOptions
                getOptionSelected={(option, value) => option.pdeb_bnaam === value.pdeb_bnaam}
                getOptionLabel={(option) => option.pdeb_bnaam}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Client (Klant)" variant="outlined" autoFocus/>}
                openOnFocus
                autoSelect
            />
        </React.Fragment>
    );
}
