import React, {useState} from "react";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {NavButton} from '../components/listItems';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import BarChartIcon from '@material-ui/icons/BarChart';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import EuroIcon from '@material-ui/icons/Euro';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Work from '@material-ui/icons/Work';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import MemoryIcon from '@material-ui/icons/Memory';
import SalesPage from './SalesPage';
import ProductivityPage from './ProductivityPage';
import FinancePage from './FinancePage';
import LedgerPage from './LedgerPage';
import PartsTable from '../components/PartsTable';
import RentalsTable from '../components/RentalsTable';
import BillingPage from './BillingPage';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AdministrationPage from '../components/AdministrationPage';
import UsersTable from '../components/UsersTable';
import {HoursReportingPage} from "./HoursReportingPage";
import {ProjectsFinancePage} from "./ProjectsFinancePage";
import {ProjectsPlanningPage} from "./ProjectsPlanningPage";
import TvtHolidayOverview from "./TvtHolidayOverview";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimesheetPage from './TimesheetPage';
import Paper from '@material-ui/core/Paper';
import {useAuth} from "../context/auth";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PoPage from '../components/PoPage';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PurchaseConflictPage from './PurchaseConflictPage';
import PurchaseSubscribePage from './PurchaseSubscribePage';
import InternExternPage from './InternExternPage';
import KpiPage from './KpiPage';
import MoodIcon from '@material-ui/icons/Mood';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Dashboard() {
    const {roles} = useAuth();
    const [title, setTitle] = useState("Dashboard");
    const [expandAdmin, setExpandAdmin] = useState(false);
    const [expandFin, setExpandFin] = useState(false);
    const [expandOps, setExpandOps] = useState(false);
    const [expandPurchase, setExpandPurchase] = useState(false);
    const [expandProjects, setExpandProjects] = useState(false);
    const open = true;
    const {setAuthTokens} = useAuth();

    const classes = useStyles();

    const handlePageChange = (title) => {
        setTitle(title);
    };

    return (
        <Router>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {title}
                        </Typography>
                        <Button onClick={() => setAuthTokens()} color="inherit" startIcon={<ExitToAppIcon/>}>
                            LOG OUT
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <Divider/>
                    <div className={classes.toolbarIcon}>
                        {
                        }
                    </div>
                    <List>
                        {/* Sales */}
                        <NavButton handleClick={handlePageChange} label="Sales" url="/sales"
                                   icon={<TrendingUpIcon/>} disabled={false}/>

                        {/* Administration */}
                        {(roles.includes("admin") || roles.includes("manager")) ?
                            <>
                                <ListItem button onClick={() => setExpandAdmin(!expandAdmin)}>
                                    <ListItemIcon>
                                        <SupervisorAccountIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Administration"/>
                                    {expandAdmin ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={expandAdmin} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Check timesheets"
                                                   url="/administration/overview" icon={<VisibilityIcon/>}
                                                   disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Timesheet"
                                                   url="/administration/timesheet" icon={<ScheduleIcon/>}
                                                   disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="TVT / Holiday"
                                                   url="/administration/tvt-holiday-overview" icon={<VisibilityIcon/>}
                                                   disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Dashboard users"
                                                   url="/administration/users" icon={<VisibilityIcon/>}
                                                   disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Intern & Extern"
                                                   url="/administration/intern-extern" icon={<VisibilityIcon/>}
                                                   disabled={false}/>
                                    </List>
                                </Collapse>
                            </>
                            :
                            <NavButton handleClick={handlePageChange} label="Timesheet"
                                       url="/administration/timesheet" icon={<ScheduleIcon/>} disabled={false}/>
                        }

                        {/* Finance */}
                        {(roles.includes("admin") || roles.includes("manager")) ?
                            <>
                                <ListItem button onClick={() => setExpandFin(!expandFin)}>
                                    <ListItemIcon>
                                        <EuroIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Finance"/>
                                    {expandFin ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={expandFin} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Liquidity"
                                                   url="/finance/liq" icon={<EuroIcon/>} disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Ledger"
                                                   url="/finance/ledger" icon={<EuroIcon/>} disabled={false}/>
                                        <NavButton nested={true} handleClick={handlePageChange} label="Totals/KPI"
                                                   url="/finance/kpi" icon={<EuroIcon/>} disabled={false}/>
                                    </List>
                                </Collapse>
                            </>
                            : <></>}

                        {/* Projects */}
                        <ListItem button onClick={() => setExpandProjects(!expandProjects)}>
                            <ListItemIcon>
                                <BarChartIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Projects"/>
                            {expandProjects ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={expandProjects} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavButton nested={true} handleClick={handlePageChange} label="Hours reporting" url="/hours"
                                           icon={<BarChartIcon/>} disabled={false}/>
                                <NavButton nested={true} handleClick={handlePageChange} label="Finance"
                                           url="/projects/finance" icon={<EuroIcon/>} disabled={false}/>
                                <NavButton nested={true} handleClick={handlePageChange} label="Planning"
                                           url="/projects/planning" icon={<EventNoteIcon/>}
                                           disabled={false}/>
                            </List>
                        </Collapse>

                        {/* Operations */}
                        <ListItem button onClick={() => setExpandOps(!expandOps)}>
                            <ListItemIcon>
                                <Work/>
                            </ListItemIcon>
                            <ListItemText primary="Operations"/>
                            {expandOps ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={expandOps} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavButton nested={true} handleClick={handlePageChange} label="Parts"
                                           url="/operations/parts" icon={<MemoryIcon/>} disabled={false}/>
                                <NavButton nested={true} handleClick={handlePageChange} label="Productivity"
                                           url="/operations/productivity" icon={<SportsKabaddiIcon/>}
                                           disabled={false}/>
                                <NavButton nested={true} handleClick={handlePageChange} label="Rentals"
                                           url="/operations/rentals" icon={<MoodIcon/>}
                                           disabled={false}/>
                            </List>
                        </Collapse>

                        {/* Service */}
                        <NavButton handleClick={handlePageChange} label="Service" url="/service"
                                   icon={<LoyaltyIcon/>} disabled={true}/>

                        {/* Purchase */}
                        <ListItem button onClick={() => setExpandPurchase(!expandPurchase)}>
                            <ListItemIcon>
                                <ShoppingCartIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Purchase"/>
                            {expandPurchase ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={expandPurchase} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavButton nested={true} handleClick={handlePageChange} label="Conflicts"
                                           url="/purchase/conflicts" icon={<ShoppingCartIcon/>}
                                           disabled={false}/>
                                <NavButton nested={true} handleClick={handlePageChange} label="Subscribe"
                                           url="/purchase/subscribe" icon={<ShoppingCartIcon/>}
                                           disabled={false}/>
                            </List>
                        </Collapse>

                        {/* Billing */}
                        {(roles.includes("admin") || roles.includes("manager") || roles.includes("device")) ?
                            <NavButton handleClick={handlePageChange} label="Billing" url="/billing/events"
                                       icon={<MemoryIcon/>} disabled={false}/>
                            : <></>
                        }
                    </List>
                    <Divider/>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <Container maxWidth="xl" className={classes.container}>
                        <div>
                            <Switch>
                                <Route path="/administration/timesheet" render={
                                    () => <TimesheetPage/>
                                }/>
                                <Route path="/administration/overview" render={
                                    () =>
                                        <AdministrationPage/>
                                }/>
                                <Route path="/administration/tvt-holiday-overview" render={
                                    () =>
                                        <Paper className={classes.paper} style={{overflow: 'hidden'}}>
                                            <TvtHolidayOverview/>
                                        </Paper>
                                }/>
                                <Route path="/administration/users" render={() => <UsersTable/>}/>
                                <Route path="/administration/intern-extern" render={() => <InternExternPage/>}/>
                                <Route path="/hours/:poNum" component={PoPage}/>
                                <Route path="/hours" render={
                                    () =>
                                        <HoursReportingPage/>
                                }/>
                                <Route path="/projects/finance" render={
                                    () =>
                                        <ProjectsFinancePage />
                                }/>
                                <Route path="/projects/planning" render={
                                    () =>
                                        <ProjectsPlanningPage />
                                }/>
                                <Route path="/sales" render={
                                    () => <SalesPage/>
                                }/>
                                <Route path="/finance/liq" render={
                                    () => <FinancePage/>
                                }/>
                                <Route path="/finance/ledger" render={
                                    () => <LedgerPage/>
                                }/>
                                <Route path="/finance/kpi" render={
                                    () => <KpiPage/>
                                }/>
                                <Route path="/operations/parts" render={
                                    () =>
                                        <PartsTable/>
                                }
                                />
                                <Route path="/operations/productivity" render={
                                    () =>
                                        <ProductivityPage/>
                                }
                                />
                                <Route path="/operations/rentals" render={
                                    () =>
                                        <RentalsTable/>
                                }
                                />
                                <Route path="/purchase/conflicts" render={
                                    () =>
                                        <PurchaseConflictPage/>
                                }
                                />
                                <Route path="/purchase/subscribe" render={
                                    () =>
                                        <PurchaseSubscribePage/>
                                }
                                />
                                <Route path="/billing/events" render={
                                    () =>
                                        <BillingPage/>
                                }
                                />
                            </Switch>
                        </div>
                    </Container>
                </main>
            </div>
        </Router>
    );
}
