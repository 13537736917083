import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import BorderLinearProgress from './BorderLineProgressBar'
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import {secondsToHm, truncateString} from '../helpers.js';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'torh_num', numeric: false, sorting: true, disablePadding: true, label: 'PO Number'},
    {id: 'pdeb_zoeknaam', numeric: false, sorting: true, disablePadding: false, label: 'Client'},
    {id: 'torh_ref_onze', numeric: false, sorting: true, disablePadding: false, label: 'Our ref'},
    {id: 'torh_ref_uw', numeric: false, sorting: true, disablePadding: false, label: 'Your ref'},
    {id: 'vc_seconds', numeric: true, sorting: true, disablePadding: false, label: 'VC total'},
    {id: 'nc_seconds', numeric: true, sorting: true, disablePadding: false, label: 'Booked'},
    {id: 'remaining_seconds', numeric: true, sorting: true, disablePadding: false, label: 'Remaining'},
    {id: 'status', numeric: false, sorting: false, disablePadding: false, label: 'Status'},
];

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell align='right'>{props.totalVC}</TableCell>
                <TableCell align='right'>{props.totalNC}</TableCell>
                <TableCell/>
                <TableCell/>
            </TableRow>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sorting ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
                                ) : null}
                            </TableSortLabel>
                            : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useFormStyles = makeStyles({
    formControl: {
        minWidth: 150,
    },
});

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

function TableRowImpl(props) {
    const row = props.row;
    const labelId = `enhanced-table-checkbox-${row.id}`;

    return (
        <React.Fragment>
            <TableRow
                hover
                onDoubleClick={() => props.handleClick(row.torh_num)}
                role="checkbox"
                tabIndex={-1}
                key={row.torh_num}
            >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    {row.torh_num}
                </TableCell>
                <TableCell>{truncateString(row["pdeb_zoeknaam"], 15)}</TableCell>
                <TableCell>{truncateString(row["torh_ref_onze"], 15)}</TableCell>
                <TableCell>{truncateString(row["torh_ref_uw"], 15)}</TableCell>
                <TableCell align='right'>{secondsToHm(row["vc_seconds"])}</TableCell>
                <TableCell align='right'>{secondsToHm(row["nc_seconds"])}</TableCell>
                <TableCell align='right'>{secondsToHm(row["remaining_seconds"])}</TableCell>
                <TableCell>{<BorderLinearProgress
                    color={(row["nc_seconds"] >= row["vc_seconds"] && !(!row["nc_seconds"] && !row["vc_seconds"])) ? "secondary" : "primary"}
                    variant="determinate" value={Math.min(100, 100 * row["nc_seconds"] / row["vc_seconds"])}/>}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();

    return (
        <Toolbar
            className={classes.root}
        >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Total PO overview &nbsp;{props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}
            </Typography>

            <Tooltip title="Filter list">
                <ReportTypeSelect handleChange={props.handleChange} hourReportType={props.hourReportType}/>
            </Tooltip>
        </Toolbar>
    );
}

function ReportTypeSelect(props) {
    const classes = useFormStyles();
    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={props.hourReportType}
                onChange={props.handleChange}
                variant="outlined"
            >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                <MenuItem value={"SERVICE"}>SERVICE</MenuItem>
                <MenuItem value={"CONV_CCC"}>CCC</MenuItem>
                <MenuItem value={"ENGINEERING"}>ENGINEER</MenuItem>
                <MenuItem value={"AAA1"}>AAA1XXX</MenuItem>
                <MenuItem value={"AAA0"}>AAA0XXX</MenuItem>
                <MenuItem value={"CPR"}>CPR</MenuItem>
                <MenuItem value={"OEM"}>OEM</MenuItem>
            </Select>
        </FormControl>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('remaining_seconds');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [hourReportType, setHourReportType] = useState("CONV_CCC");
    const [rows, setRows] = useState([]);
    const [shouldUpdate, setShouldUpdate] = useState(0); // So email adress get's reloaded

    useEffect(() => {
        setLoading(true);
        setRows([]);
        flaskAPI.get('/api/pos/joined')
            .then(response => {
                setLoading(false);
                setRows(response.data);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const handleReportTypeChange = (event) => {
        setHourReportType(event.target.value);
        setPage(0);
        setShouldUpdate(shouldUpdate + 1);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setShouldUpdate(shouldUpdate + 1);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setShouldUpdate(shouldUpdate + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setShouldUpdate(shouldUpdate + 1);
    };

    const filteredRows = hourReportType !== 'ALL' ? rows.filter(row => row.part_code.includes(hourReportType)) : rows
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <EnhancedTableToolbar loading={loading} handleChange={handleReportTypeChange}
                                  hourReportType={hourReportType}/>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='small'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredRows.length}
                        totalVC={secondsToHm(filteredRows.reduce((total, obj) => obj["vc_seconds"] + total, 0))}
                        totalNC={secondsToHm(filteredRows.reduce((total, obj) => obj["nc_seconds"] + total, 0))}
                    />
                    <TableBody>
                        {stableSort(filteredRows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (<TableRowImpl shouldUpdate={shouldUpdate} row={row}
                                                      handleClick={props.handleClick}/>);
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 33 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <FormLabel>(Double click on a row for more info.)</FormLabel>
        </div>
    );
}
