import React, {useEffect, useState} from 'react';
import MaterialTable, {MTableToolbar} from 'material-table';
import ReportTypeSelect from "./ReportTypeSelect";
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})
const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function Timesheet(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [hourReportType, setHourReportType] = useState("ALL");
    const filteredRows = hourReportType !== 'ALL' ? data.filter(row => row.part_code.includes(hourReportType)) : data



    const handleReportTypeChange = (event) => {
        setHourReportType(event.target.value);
        //setPage(0);
        //setShouldUpdate(shouldUpdate + 1);
    };

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/projects')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const columns = [
        {
            title: 'PO Number',
            field: 'torh_num',
        },
        {
            title: 'Client',
            field: 'pdeb_zoeknaam',
        },
        {
            title: 'Our ref',
            field: 'torh_ref_onze',
        },
        {
            title: 'VK leverdatum',
            field: 'vobr_dat_planning',
            type: 'date'
        },
        {
            title: 'PO datum',
            field: 'torh_dat_plan',
            type: 'date'
        },
        {
            title: 'Eerste IK',
            field: 'ik_first_date',
            type: 'date'
        },
        {
            title: 'Laatste IK',
            field: 'ik_last_date',
            type: 'date'
        },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Projects Planning
                        </Typography>
                    </div>
                }
                columns={columns}
                data={filteredRows}
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                    padding: 'dense',
                    pageSize: 25,
                    pageSizeOptions: [10, 25]
                }}
                components={{
                    Toolbar: props => (
                        <div style={{ padding: "10px", textAlign: "left" }}>
                        <Grid container direction="row" justifyContent="flex-end">
                            <Grid item xs={10}>
                                <MTableToolbar {...props} />
                            </Grid>
                            <Grid item xs={2}>
                                <Box display="flex" justifyContent="flex-end">
                                <ReportTypeSelect handleChange={handleReportTypeChange} hourReportType={hourReportType}/>
                                </Box>
                            </Grid>
                        </Grid>
                        </div>
                    )
                }}
            />
        </>
    );
}

export default withStyles(styles)(Timesheet);
