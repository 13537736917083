import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import Title from './Title';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import CircularProgress from '@material-ui/core/CircularProgress';
import LedgerPlotter from './LedgerPlotter';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    notation: "compact",
    compactDisplay: "short"
})

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const plotColors = ["#cc5f43", "#8176cc", "#ac973e", "#c75a93", "#5ba966"]

export default function LedgerChart() {
    const classes = useStyles();
    const theme = useTheme();
    const {setAuthTokens} = useAuth();
    const [plotData, setPlotData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [plotOptions, setPlotOptions] = useState();


    useEffect(() => {
        if (plotOptions) {
            setPlotData([]);
            setLoading(true);

            let ledgers = plotOptions["ledger"].map(function (e) {
                return e.split(' ')[0];
            });
            let sums = plotOptions["sum"].map(function (e) {
                return e.split(' ')[0];
            });

            flaskAPI.get('/api/ledgers/cumsum', {
                params: {
                    'date_from': plotOptions["fromDate"],
                    'date_till': plotOptions["tillDate"],
                    'ledger_list': ledgers,
                    'sum_list': sums,
                    'ledgers_negative': plotOptions["ledgersNegative"],
                    'sum_negative': plotOptions["sumNegative"],
                }
            }).then(response => {
                setPlotData(response.data);
                setLoading(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [plotOptions, setAuthTokens]);

    function formatXAxis(tickItem) {
        return moment(tickItem).format('MMM-YY')
    }

    function formatTooltipDate(tickItem) {
        return moment(tickItem).format('DD MMMM YYYY')
    }

    return (
        <React.Fragment>
            <Grid container direction="row" spacing={3} justify="center">
                {/* LedgerPlotter */}
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <LedgerPlotter onSubmit={(ledger, sum, ledgersNegative, sumNegative, fromDate, tillDate) => {
                            setPlotOptions({ledger, sum, ledgersNegative, sumNegative, fromDate, tillDate})
                        }}/>
                    </Paper>
                </Grid>

                {/* Chart */}
                <Grid item xs={9}>
                    <Paper className={classes.paper}>
                        <Title>Ledger plot {loading ? (
                            <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
                        <LineChart width={1000} height={300} data={plotData}
                                   margin={{
                                       top: 16,
                                       right: 0,
                                       bottom: 0,
                                       left: 24,
                                   }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={true}/>
                            <Legend/>

                            <Tooltip formatter={(value) => {
                                return formatter.format(value);
                            }
                            }
                                     labelFormatter={(tick) => formatTooltipDate(tick)}
                            />

                            <XAxis dataKey="date" stroke={theme.palette.text.secondary}
                                   tickFormatter={tick => {
                                       return formatXAxis(tick);
                                   }}
                            />
                            <YAxis stroke={theme.palette.text.secondary}
                                   tickFormatter={tick => {
                                       return formatter.format(tick);
                                   }}

                            >
                                <Label
                                    angle={270}
                                    position="left"
                                    style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                                    margin={{right: 120}}
                                >
                                    Amount (€)
                                </Label>
                            </YAxis>
                            {plotOptions ? plotOptions["ledger"].map(
                                (ledger, index) =>
                                    <Line type="monotone" name={ledger} dataKey={ledger.split(' ')[0]}
                                          stroke={plotColors[index % 5]} dot={false}/>
                            ) : <></>}
                            {plotOptions ? plotOptions["sum"] ? plotOptions["sum"].length > 0 ?
                                <Line type="monotone" name={"Sum of " + plotOptions["sum"].join(", ")}
                                      dataKey="netto_amount" stroke={plotColors[4]} dot={false}/>
                                : <></> : <></> : <></>}
                        </LineChart>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
