import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function OperationForm(props) {
    return (
        <React.Fragment>
            <Autocomplete
                id="combo-box-demo"
                value={props.value}
                onChange={props.handleChange}
                options={props.operationList}
                filterSelectedOptions
                getOptionLabel={(option) => option.torb_oms}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Operation (Bewerking)" variant="outlined"
                                                    autoFocus/>}
                openOnFocus
                autoSelect
            />
        </React.Fragment>
    );
}
