import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import WrikeLogo from '../img/wrike.svg'
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const WrikeIcon = () => (
    <Icon>
        <img alt="logo of wrike" src={WrikeLogo} height={25} width={25}/>
    </Icon>
)

export default function WrikeButton(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [permaLink, setPermaLink] = React.useState('#');

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleButtonClick = () => {
        let m = moment();
        let fromDate = m.format('YYYY-MM-DD');
        let tillDate = fromDate;

        let title = 'Uren boeken week ' + props.weekNumber;
        let desc = 'Voor week ' + props.weekNumber + ' ben je vergeten om uren te boeken. Graag zsm doen. (For comments: don\'t respond to this bot but please to someone from the secretary\'s office.)';

        if (!loading) {
            setLoading(true);
            flaskAPI.get('/api/wriketask/' + title + '/' + desc + '/' + props.firstName + '/' + props.lastName + '/' + fromDate + '/' + tillDate)
                .then(response => {
                    setPermaLink(response.data.data[0]["permalink"])
                    setSuccess(true);
                    setLoading(false);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setSuccess(false);
                    setLoading(false);
                });
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={loading}
                    onClick={handleButtonClick}
                    endIcon={<WrikeIcon/>}
                >
                    Wrike Task
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
            <div className={classes.wrapper}>
                <Button href= "" color="primary" target="_blank" onClick={() =>
                    window.open(permaLink, "_blank")} disabled={!success}>
                    Open in wrike
                </Button>
            </div>
        </div>
    );
}
