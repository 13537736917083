import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {formatDate} from '../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formLabel: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function ComboBox(props) {
    return (
        <Autocomplete
            multiple
            id="tags-standard"
            options={props.ledgers}
            getOptionLabel={(option) => option}
            onChange={props.onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    style={{minWidth: 300}}
                />
            )}
        />
    );
}

export default function LedgerPlotter(props) {
    const {setAuthTokens} = useAuth();
    const [ledgers, setLedgers] = useState([]);
    const [ledgersNegative, setLedgersNegative] = useState(false);
    const [sumNegative, setSumNegative] = useState(false);

    useEffect(() => {
        if (!ledgers.length) {
            flaskAPI.get('/api/ledgers/list').then(response => {
                setLedgers(response.data);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [ledgers.length, props.setAuthTokens, setAuthTokens]);
    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth() - 1);
    const tillDateInitial = new Date();

    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [tillDate, setTillDate] = useState(tillDateInitial);

    const [ledger, setLedger] = useState([]);
    const [sum, setSum] = useState([]);

    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);
    const ledgerError = ledger.length === 0;
    const error = ledgerError || fromDateError || tillDateError;

    const classes = useStyles();

    const handleLedgersNegativeChange = (event) => {
        setLedgersNegative(event.target.checked);
    };
    const handleSumNegativeChange = (event) => {
        setSumNegative(event.target.checked);
    };

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isValidDate(fromDate) && isValidDate(tillDate)) {
            props.onSubmit(ledger, sum, ledgersNegative, sumNegative, formatDate(fromDate), formatDate(tillDate))
        }
    }
    const handleLedgerChange = (event, newValue) => {
        setLedger(newValue);
    }
    const handleSumChange = (event, newValue) => {
        setSum(newValue);
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" error={fromDateError || tillDateError}
                             className={classes.formControl}>
                    <FormLabel component="legend">Period</FormLabel>
                    <FormGroup>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                views={["year", "month"]}
                                error={fromDateError}
                                disableToolbar
                                variant="inline"
                                format="MM/yyyy"
                                margin="normal"
                                id="fromDate"
                                label="Date from"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                views={["year", "month"]}
                                error={tillDateError}
                                disableToolbar
                                variant="inline"
                                format="MM/yyyy"
                                margin="normal"
                                id="tillDate"
                                label="Date till"
                                value={tillDate}
                                onChange={handleTillDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDateMessage="Date should not be in future"
                            />
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </FormControl>
                {/* Select ledger number */}
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        <FormLabel error={ledgerError} required={true} component="legend" className={classes.formLabel}>Ledger
                            (plotted seperate)</FormLabel>
                        <ComboBox ledgers={ledgers} onChange={handleLedgerChange}/>

                        <FormControlLabel
                            value="end"
                            control={<Checkbox color="primary" onChange={handleLedgersNegativeChange}/>}
                            label="Show negative"
                            labelPlacement="end"
                        />
                        <FormLabel required={false} component="legend" className={classes.formLabel}>Sum</FormLabel>
                        <ComboBox ledgers={ledgers} onChange={handleSumChange}/>
                    </FormGroup>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" onChange={handleSumNegativeChange}/>}
                        label="Show negative"
                        labelPlacement="end"
                    />
                </FormControl>
                <FormGroup>
                    <Button disabled={error} type="submit" variant="outlined" color="primary"
                            className={classes.button}>
                        Show me the ledgers
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}

