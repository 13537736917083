import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useFormStyles = makeStyles({
    formControl: {
        minWidth: 150,
    },
});

export default function ReportTypeSelect(props) {
    const classes = useFormStyles();
    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={props.hourReportType}
                onChange={props.handleChange}
                variant="outlined"
            >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                <MenuItem value={"SERVICE"}>SERVICE</MenuItem>
                <MenuItem value={"CONV_CCC"}>CCC</MenuItem>
                <MenuItem value={"ENGINEERING"}>ENGINEER</MenuItem>
                <MenuItem value={"AAA1"}>AAA1XXX</MenuItem>
                <MenuItem value={"AAA0"}>AAA0XXX</MenuItem>
                <MenuItem value={"CPR"}>CPR</MenuItem>
                <MenuItem value={"OEM"}>OEM</MenuItem>
            </Select>
        </FormControl>
    )
}