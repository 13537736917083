import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from "moment";

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
})

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));


function getKey(key, data) {
    if (key in data) {
        return data[key];
    }
    return 0;
}

function format(val) {
    return formatter.format(val);
}


export default function BasicTable() {
    const {setAuthTokens} = useAuth();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [saldo, setSaldo] = useState(0);
    const rows = (data && 'total' in data && 'verlopen' in data) ? [
        {
            "caption": "Saldo betaalrekening",
            "total": format(saldo),
            "nested": false
        },
        // Empty row
        {
            "caption": " ", "total": " ", "verlopen": " ",
        },
        {
            "caption": "totale geboekte inkoop",
            "total": format(getKey("cred", data['total'])),
            "verlopen": format(getKey("cred", data['verlopen'])),
            "nested": true
        },
        {
            "caption": "totale ongefactureerde inkoop",
            "total": format(getKey("purch", data['total'])),
            "verlopen": format(getKey("purch", data['verlopen'])),
            "nested": true
        },
        {
            "caption": "totale inkoopbehoefte",
            "total": format(0.35 * getKey("new_sales", data['total'])),
            "nested": true
        },
        {
            "caption": "Inkoop totaal",
            "total": format(getKey("purch", data['total']) + getKey("cred", data['total']) + 0.35 * getKey("new_sales", data['total'])),
            "nested": false
        },
        // Empty row
        {
            "caption": " ", "total": " ", "verlopen": " ",
        },
        {
            "caption": "aangemaakt & gefiatteerd",
            "total": format(getKey("new_sales", data['total'])),
            "nested": true
        },
        {
            "caption": "Totale openstaande verkoop",
            "total": format(getKey("active_sales", data['total'])),
            "nested": false
        },
        // Empty row
        {
            "caption": " ", "total": " ", "verlopen": " ",
        },
        {
            "caption": "openstaande debiteuren",
            "total": format(getKey("deb", data['total'])),
            "verlopen": format(getKey("deb", data['verlopen'])),
            "nested": true
        },
        {
            "caption": "openstaande termijnfacturen",
            "total": format(getKey("order_term", data['total'])),
            "verlopen": format(getKey("order_term", data['verlopen'])),
            "nested": true
        },
        {
            "caption": "openstaande eindfacturen",
            "total": format(getKey("order_end", data['total'])),
            "verlopen": format(getKey("order_end", data['verlopen'])),
            "nested": true
        },
        {
            "caption": "Som alle debiteurenposities",
            "total": format(getKey("deb", data['total']) + getKey("order_term", data['total']) + getKey("order_end", data['total'])),
            "nested": false
        },
    ] : []

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/totals-kpi')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    let now = moment();
    let nextMonth = moment(now).add(1, 'month')

    useEffect(() => {
        if (!saldo) {
            flaskAPI.get('/api/ledgers/cumsum?date_from=' + now.format('YYYY-MM-01') + '&date_till=' + nextMonth.format('YYYY-MM-01') + '&ledger_list[]=1225').then(response => {
                if (Array.isArray(response.data) && "1225" in response.data[0]) {
                    setSaldo((response.data[0]["1225"]).toFixed(0));
                }
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [nextMonth, now, saldo, setAuthTokens]);

    useEffect(() => {
        if (!saldo) {
            setLoading(true);
            flaskAPI.get('/api/ledgers/cumsum?date_from=' + now.format('YYYY-MM-01') + '&date_till=' + nextMonth.format('YYYY-MM-01') + '&ledger_list[]=1225').then(response => {
                if (Array.isArray(response.data) && "1225" in response.data[0]) {
                    setSaldo((response.data[0]["1225"]).toFixed(0));
                }
                setLoading(false);
            })
                .catch(error => {
                    setLoading(false);
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [nextMonth, now, saldo, setAuthTokens]);

    return (
        <Paper className={classes.paper}>
            <TableContainer>
                <Title>
                    KPIs
                    {loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}
                </Title>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell><b>Totaal</b></TableCell>
                            <TableCell><b>Verlopen</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow>
                                <TableCell style={row.nested ? {paddingLeft: 30} : {}}>{row.caption}</TableCell>
                                <TableCell>{row.total ? row.total : "-"}</TableCell>
                                <TableCell>{row.verlopen ? row.verlopen : "-"}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

