import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MailIcon from '@material-ui/icons/Mail';
import HistoryIcon from '@material-ui/icons/History';

function TimeButton(props) {
    return (
        <Grid item>
            <Button variant="contained" onClick={props.onClick}>
                {props.value}
            </Button>
        </Grid>
    );
}

export default function TimeWindowButtons(props) {
    function renderButton(i, caption) {
        return (
            <TimeButton
                value={caption}
                onClick={() => props.onClick(i)}
            />
        );
    }

    return (
        <Grid container spacing={1}>
            {renderButton(0, "This month")}
            {renderButton(1, "Last month")}
            {renderButton(2, "2 months ago")}
            {renderButton(3, "3 months ago")}
            {renderButton(-1, "All")}
            <Grid item xs={4}/>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PictureAsPdfIcon/>}
                    onClick={() => props.onPdf()}
                >
                    PDF
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<MailIcon/>}
                    onClick={() => props.onMail()}
                >
                    Mail
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<HistoryIcon/>}
                    onClick={() => props.onBookBack()}
                >
                    Book back
                </Button>
            </Grid>
        </Grid>
    );
}

