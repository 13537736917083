import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import PurchaseSubscribeTable from '../components/PurchaseSubscribeTable';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 701.5,
    },
}));

export default function PurchaseSubscribePage() {
    const classes = useStyles();
    clsx(classes.paper, classes.fixedHeight2);
    return (
        <Grid container spacing={3}>
            {/* Time Window
    <Grid item xs={3}>
    <Paper className={classes.paper}>
    <PurchaseSubscribeSettings onSubmit={(options) => {
    }
    } />
    </Paper>
    </Grid>*/}
            {/* Chart */}
            {/*<Grid item xs={9}>*/}
            <Grid item xs={12}>
                <PurchaseSubscribeTable/>
            </Grid>
        </Grid>
    )
}
