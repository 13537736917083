import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {secondsToHm} from '../helpers'

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    normalCell:{
    },
    greenCell:{
        backgroundColor: '#b2ff59',
    },
    orangeCell: {
        backgroundColor: '#ffc107',
        fontWeight: 'bold'
    },
    redCell: {
        backgroundColor: '#f44336',
        fontWeight: 'bold'
    },
});


const days = ['Mo', 'Di', 'Wo', 'Do', 'Fr', 'Sa', 'Su'];

function createDay(dayNum, val) {
    let color = val < 1 ? 'white' :
        val <= 3600*9 ? '#b2ff59' :
            val <= 3600*10 ? '#ffc107' :
                '#f44336';
    return (
        <Grid container item spacing={1} direction="row" style={{width: '100px'}}>
            <Grid item>{days[dayNum]}:</Grid>
            <Grid item style={{backgroundColor: color}}>{secondsToHm(val)}</Grid>
        </Grid>
    );
}


function createDays(weekData) {
    return (
        <Grid container item spacing={2} xs={8} direction="row" alignItems="flex-start">
            {createRow(weekData)}
        </Grid>
    );
}

function createRow(hours) {
    let data = [];
    weekDayLoop: for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 7; j++) {
            if (hours && hours[j] && hours[j]["week_day"] === i) {
                data.push(createDay(i, hours[j]["uren_man"]))
                continue weekDayLoop;
            }
        }
        data.push(createDay(i, 0))
    }
    return (<>{data}</>);
}


function EmployeeInfo(props) {
    const {classes} = props;
    const tvtSeconds = props.infoData ? props.infoData["mdwa_tvt_stand"] : 0

    return (
        <div className={classes.root}>
            <Grid container direction="row" alignItems="flex-end" spacing={1}>
                {createDays(props.weekData)}
                <Grid container item spacing={1} xs={2} direction="row" justify="flex-end">
                    <Grid item>TVT value:</Grid>
                    <Grid item className={tvtSeconds >= 3600*20 ? classes.redCell : tvtSeconds >= 3600*15 ? classes.orangeCell : classes.normalCell}>{props.infoData ? secondsToHm(tvtSeconds) : ''}</Grid>
                </Grid>
                <Grid container item spacing={1} xs={2} direction="row" justify="flex-end">
                    <Grid item>Holiday hours left:</Grid>
                    <Grid item>{props.infoData ? secondsToHm(props.infoData["mdwa_vakantieuren_rest"]) : ''}</Grid>
                </Grid>
            </Grid>
            {tvtSeconds >= 3600*20 ?
            <Grid container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item xs={6} />
                <Grid item xs={6} style={{fontWeight: 'bold'}}>You're working additional hours for free now!! Take a couple of hours off per day to make me smaller!!</Grid>
            </Grid>
                : <></>}
        </div>
    );
}

export default withStyles(styles)(EmployeeInfo);
