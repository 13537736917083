import React from 'react';
import Grid from '@material-ui/core/Grid';
import LedgerChart from '../components/LedgerChart';

export default function LedgerPage() {
    return (
        <Grid container spacing={3}>
            <LedgerChart/>
        </Grid>
    )
}
