import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

function IKPOTable() {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [hidden, setHidden] = useState();
    const [filteredData, setFilteredData] = useState();

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/purchase/ikvspo')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
        flaskAPI.get('/api/purchase/hidden')
            .then(response => {
                if (response.status === 200) {
                    setHidden(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    useEffect(() => {
        setFilteredData(
            data ? hidden ? data.filter(row => {
                for (let i = 0; i < hidden.length; i++) {
                    if (row["torh_num"] === hidden[i]["torh_num"] && row["ikwh_num"] === hidden[i]["ikwh_num"]) {
                        return false;
                    }
                }
                return true;
            }) : data : []
        );
    }, [data, hidden]);

    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.post('/api/purchase/hide', oldData).then(response => {
                        if (response.status === 201) {
                            const dataDelete = [...filteredData];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setFilteredData([...dataDelete]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 200)
        });
    }

    const columns = [
        {
            title: 'PO',
            field: 'torh_num',
        },
        {
            title: 'Omschrijving',
            field: 'torh_oms',
        },
        {
            title: 'Datum planning',
            field: 'torh_dat_plan',
            type: 'date',
        },
        {
            title: 'Inkoop ord.',
            field: 'ikwh_num',
        },
        {
            title: 'Datum levering',
            field: 'ikwh_dat_lev',
            type: 'date',
        },
        {
            title: 'Dagen tussen planning en levering',
            field: 'date_diff',
            numeric: true,
            customSort: (a, b) => a["date_diff"] < b["date_diff"]
        },
    ];
    return (
        <MaterialTable
            components={{Container: props => <Paper {...props} elevation={0}/>}}
            icons={tableIcons}
            isLoading={loading}
            title=''
            columns={columns}
            data={filteredData}
            editable={{
                onRowDelete: handleDelete,
            }}
            options={{
                pageSize: 10,
                paging: true,
                search: true,
                sorting: true,
            }}
        />
    );
}

export default withStyles(styles)(IKPOTable);
