import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateForm from './DateForm';
import ClientForm from './ClientForm';
import ProjectForm from './ProjectForm';
import OperationForm from './OperationForm';
import HourForm from './HourForm';
import MemoForm from './MemoForm';
import {flaskAPI} from '../../flaskAPI';
import {useAuth} from "../../context/auth";
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import SubmitButton from '../SubmitButton';
import {formatDate, parseHourString} from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    stepper: {
        padding: theme.spacing(0, 0, 5),
    },
    content: {
        width: 400,
        height: 100
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const steps = ['Date', 'Client', 'Project', 'Operation', 'Hours', 'Memo'];
const emptyProject = {torh_num: '', torh_oms: ''}
const emptyClient = {pdeb_bnaam: ''};
const emptyOperation = {torb_oms: ''};
const nextButtonRef = React.createRef();

export default function AddHourStepper(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [clientList, setClientList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [date, setDate] = useState(new Date());
    const [client, setClient] = useState(emptyClient);
    const [project, setProject] = useState(emptyProject);
    const [operation, setOperation] = useState(emptyOperation);
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    // <- This code is to auto focus the next button after an input has been selected. Kinda ugly.
    useEffect(() => {
        if (client && client !== emptyClient) {
            nextButtonRef.current.focus()
        }
    }, [client]);

    useEffect(() => {
        if (project && project !== emptyProject) {
            nextButtonRef.current.focus()
        }
    }, [project]);

    useEffect(() => {
        if (operation && operation !== emptyOperation) {
            nextButtonRef.current.focus()
        }
    }, [operation]);
    // End  ->


    const today = new Date();
    today.setHours(8);
    today.setMinutes(0);
    const [hours, setHours] = useState("8:00");
    const [hourError, setHourError] = useState(false);
    const [memo, setMemo] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);

    useEffect(() => {
        if (!clientList.length) {
            flaskAPI.get('/api/clients/active')
                .then(response => {
                    let list = response.data;
                    list.push(emptyClient);
                    setClientList(list);
                    setClient(emptyClient);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [clientList.length, setAuthTokens]);

    useEffect(() => {
        if (client && client.pdeb_bnaam !== '') {
            flaskAPI.get('/api/clients/' + client.pdeb_deb_num + '/pos')
                .then(response => {
                    let list = response.data;
                    list.push(emptyProject);
                    setProjectList(list);
                    setProject(emptyProject);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [client, setAuthTokens]);

    useEffect(() => {
        if (project && project.torh_num !== '') {
            flaskAPI.get('/api/pos/' + project.torh_num + '/ops')
                .then(response => {
                    let list = response.data;
                    list.push(emptyOperation);
                    setOperationList(list);
                    setOperation(emptyOperation);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [project, setAuthTokens]);

    function getEnableNext(step) {
        switch (step) {
            case 0:
                return true;
            case 1:
                return client ? client.pdeb_bnaam !== '' : false;
            case 2:
                return project ? project.torh_num !== '' : false;
            case 3:
                return operation ? operation.torb_oms !== '' : false;
            case 4:
                return !hourError;
            case 5:
                return (memo && memo !== '');
            case 6:
                return true;
            default:
                throw new Error('Unknown step');
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <DateForm value={date} handleChange={(newValue) =>
                    setDate(newValue)
                }/>;
            case 1:
                return <ClientForm clientList={clientList} value={client}
                                   handleChange={(event, newValue) => setClient(newValue)}/>;
            case 2:
                return <ProjectForm projectList={projectList} value={project}
                                    handleChange={(event, newValue) => setProject(newValue)}/>;
            case 3:
                return <OperationForm operationList={operationList} value={operation}
                                      handleChange={(event, newValue) => setOperation(newValue)}/>;
            case 4:
                return <HourForm error={hourError} parsedValue={parseHourString(hours)} value={hours} handleChange={
                    (event) => {
                        let newHours = event.target.value;
                        setHours(newHours)
                        if (newHours.match("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")) {
                            setHourError(false);
                        } else if (newHours.match("^[0-9]*[.,]?[0-9]*$")) {
                            setHourError(false);
                        } else {
                            setHourError(true);
                        }
                    }
                }/>;
            case 5:
                return <MemoForm value={memo} handleChange={(newValue) => setMemo(newValue)}/>;
            case 6:
                return '';
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        let newStep = activeStep + 1;
        if (nextStep) {
            newStep = nextStep;
            setNextStep(0);
        }
        setSubmitErrorMessage("");
        if (newStep === steps.length) {
            setSubmitLoading(true);
            let seconds = parseHourString(hours);
            let newValue = {
                pbwh_num: operation.pbwh_num,
                pbws_num: operation.pbws_num,
                torh_num: project.torh_num,
                uren_datum: formatDate(date),
                uren_man: seconds,
                uren_memo: memo,
            }
            props.handleAdd(newValue).then(() => setActiveStep(newStep)).catch(() => setSubmitErrorMessage("Fledge error")).finally(() => setSubmitLoading(false));
        } else {
            setActiveStep(newStep);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label, index) => (
                        <Step key={label}
                              completed={nextStep ? (index < nextStep && index !== activeStep) : index < activeStep}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            Thank you for submitting your hours.
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            spacing={3}
                        >
                            <Grid item
                                  container
                                  direction="row"
                                  spacing={3}
                            >
                                <Grid item xs={6}>
                                    <Button style={{width: '240px'}} variant="contained" color="inherit"
                                            onClick={() => {
                                                setActiveStep(3);
                                            }
                                            }>Same day / Same project</Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button style={{width: '240px'}} variant="contained" color="inherit"
                                            onClick={() => {
                                                setActiveStep(1);
                                            }
                                            }>Same day / Diff project</Button>
                                </Grid>
                            </Grid>
                            <Grid item
                                  container
                                  direction="row"
                                  spacing={3}
                            >

                                <Grid item xs={6}>
                                    <Button style={{width: '240px'}} variant="contained" color="inherit"
                                            onClick={() => {
                                                setActiveStep(0);
                                                setNextStep(3);
                                            }
                                            }>Diff day / Same project</Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button style={{width: '240px'}} variant="contained" color="inherit"
                                            onClick={() => {
                                                setActiveStep(0);
                                            }
                                            }>Diff day / Diff project</Button>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={props.onClose}>Done</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={classes.content}>
                            {getStepContent(activeStep)}
                        </div>
                        <div className={classes.buttons}>
                            <div>
                                {activeStep === steps.length - 1 ?
                                    <SubmitButton loading={submitLoading} disabled={!getEnableNext(activeStep)}
                                                  onClick={handleNext}/> :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!getEnableNext(activeStep)}
                                        ref={nextButtonRef}
                                    >
                                        Next
                                    </Button>
                                }
                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                    Back
                                </Button>
                            </div>
                            <div>
                                <FormHelperText error id="component-helper-text">{submitErrorMessage}</FormHelperText>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}
