import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {EmployeeSelect, PasswordField, RoleSelect} from './UserControls';
import {FormControl} from '@material-ui/core';
import SubmitButton from './SubmitButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import {dialogStyles, formStyles} from '../styles';

const DialogTitle = withStyles(dialogStyles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function SimpleDialog(props) {
    const classes = makeStyles(formStyles);
    const {onClose, open} = props;

    const [values, setValues] = useState({
        username: "",
        password: "",
        employee_id: null,
        roles: "employee",
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    const error = !(values.username && values.password && values.roles);

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClose = () => {
        onClose();
    };
    const handleSubmit = () => {
        setLoading(true);
        setSuccess(false);
        setSubmitErrorMessage("");
        props.onCreate(values)
            .then(() => {
                setLoading(false);
                setSuccess(true);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.data && error.response.data.message) {
                    setSubmitErrorMessage(error.response.data.message);
                } else {
                    setSubmitErrorMessage("Something went wrong.");
                }
            })
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle onClose={handleClose}>Add User</DialogTitle>
            <DialogContent>
                <form className={classes.root} noValidate autoComplete="off">
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="username"
                                label="Username"
                                value={values.username}
                                onChange={handleChange('username')}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <PasswordField
                                label="Password"
                                value={values.password}
                                onChange={handleChange('password')}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <EmployeeSelect
                                label="Employee number"
                                value={values.employee_id}
                                onChange={handleChange('employee_id')}
                                variant="outlined"
                                helperText="Only required if the user wants to fill in timesheets."
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <RoleSelect
                                label="Role"
                                value={values.roles}
                                onChange={handleChange('roles')}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormHelperText error id="component-helper-text">{submitErrorMessage}</FormHelperText>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <SubmitButton disabled={error} loading={loading} onClick={handleSubmit} success={success}/>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};


