import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function TimeWindowSelectorProd(props) {
    const fromDateInitial = new Date();
    fromDateInitial.setMonth(fromDateInitial.getMonth() - 1);
    const tillDateInitial = new Date();

    const [interval, setInterval] = useState('week');
    const [relative, setRelative] = useState(false);
    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [tillDate, setTillDate] = useState(tillDateInitial);
    const [state, setState] = React.useState({
        showProd: true,
        showUnProd: true,
    });
    const {showProd, showUnProd} = state;
    const plotTypeError = [showProd, showUnProd].filter((v) => v).length < 1;
    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);
    const error = plotTypeError || fromDateError || tillDateError;

    const classes = useStyles();

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };
    const handleIntervalRadioChange = (event) => {
        setInterval(event.target.value);
    };

    const handleRelativeRadioChange = (event) => {
        setRelative(event.target.value === "true");
    };

    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isValidDate(fromDate) && isValidDate(tillDate)) {
            let plotType = {showProd, showUnProd}
            props.onDateChanged(plotType, interval, relative, fromDate, tillDate);
        }
    }
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" error={fromDateError || tillDateError}
                             className={classes.formControl}>
                    <FormLabel component="legend">Period</FormLabel>
                    <FormGroup>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                error={fromDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="fromDate"
                                label="Date from"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                error={tillDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="tillDate"
                                label="Date till"
                                value={tillDate}
                                onChange={handleTillDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDateMessage="Date should not be in future"
                            />
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Interval</FormLabel>
                    <FormGroup>
                        <RadioGroup aria-label="interval" name="interval1" value={interval}
                                    onChange={handleIntervalRadioChange}>
                            <FormControlLabel value="week" control={<Radio/>} label="Week"/>
                            <FormControlLabel value="month" control={<Radio/>} label="Month"/>
                            <FormControlLabel value="quarter" control={<Radio/>} label="Quarter"/>
                            <FormControlLabel value="year" control={<Radio/>} label="Year"/>
                        </RadioGroup>
                    </FormGroup>
                </FormControl>

                <FormControl required error={plotTypeError} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Data type</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={showProd} onChange={handleChange} name="showProd"/>}
                            label="Productive hours"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={showUnProd} onChange={handleChange} name="showUnProd"/>}
                            label="Unproductive hours"
                        />
                    </FormGroup>
                </FormControl>
                <FormControl required error={plotTypeError} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Display</FormLabel>
                    <FormGroup>
                        <RadioGroup aria-label="relative" name="relative1" value={String(relative)}
                                    onChange={handleRelativeRadioChange}>
                            <FormControlLabel value="false" control={<Radio/>} label="Hours"/>
                            <FormControlLabel value="true" control={<Radio/>} label="Percentage"/>
                        </RadioGroup>
                    </FormGroup>
                </FormControl>
                <FormGroup>
                    <Button disabled={error} type="submit" variant="outlined" color="primary"
                            className={classes.button}>
                        Show me the hours
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}

