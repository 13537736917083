import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from './Title';
import {secondsToHm} from '../helpers';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));


function createRow(key, value, color) {
    return (
        <TableRow>
            <TableCell>
                <Typography variant="p" component="p" color={color}>
                    {key}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="p" component="p" color={color}>
                    {secondsToHm(value)}
                </Typography>
            </TableCell>
        </TableRow>
    );
}


function GroupTable(props) {
    const classes = useStyles();
    const total = props.data ? props.data.total : 0;
    const unprod = props.data ? props.data["total_unprod"] : 0;

    return (
        <>
            <Grid item>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Title>
                            {props.data.groupname}
                        </Title>
                        {props.data.date_from + ' / ' + props.data.date_till}
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Operation</b></TableCell>
                                    <TableCell><b>Hours</b></TableCell>
                                </TableRow>
                            </TableHead>
                            {createRow("Totaal", total, "")}
                            {createRow("Productive", total - unprod, "primary")}
                            {createRow("Unproductive", unprod, "secondary")}

                            <TableBody>
                                {props.data && props.data.hours.map((row) => createRow(row["pbws_oms"], row.uren_man, ""))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </>
    )
}

export default function BasicTable(props) {
    return (
        <Grid container direction="row" spacing={3} justify="center">
            {props.loading ? (<CircularProgress color="secondary" size={52}/>) : (
                props.data && props.data.map((groupData) => (
                    <GroupTable data={groupData}/>
                ))
            )}
        </Grid>
    );
}

