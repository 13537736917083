import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ProdChart(props) {
    const theme = useTheme();
    const relFormatter = (tick) => `${tick * 100}%`;
    const absFormatter = (tick) => `${tick}`;

    return (
        <React.Fragment>
            <Title>Productive Hours {props.loading ? (
                <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <ResponsiveContainer>
                <BarChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                    <Legend/>
                    <Tooltip/>
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary}/>
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={props.relative ? relFormatter : absFormatter}>
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Hours
                        </Label>
                    </YAxis>
                    {(props.plotType && props.plotType.showProd) ?
                        <Bar dataKey={props.relative ? "seconds_prod_rel" : "seconds_prod"} name="Productive"
                             fill={theme.palette.primary.main}/> :
                        ""}

                    {(props.plotType && props.plotType.showUnProd) ?
                        <Bar dataKey={props.relative ? "seconds_unprod_rel" : "seconds_unprod"} name="Unproductive"
                             fill={theme.palette.secondary.main}/> :
                        ""}

                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
