import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    notation: "compact",
    compactDisplay: "short"
})

export default function FinanceChart(props) {
    const theme = useTheme();
    return (
        <React.Fragment>
            <Title>Debtors & Creditors {props.loading ? (
                <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            {/* Bar chart income */}
            <ResponsiveContainer>
                <ComposedChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={true}/>
                    <Legend/>
                    <Tooltip formatter={(value) => {
                        return formatter.format(value);
                    }
                    }
                    />
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary} padding={{right: 60, left: 60}}/>
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={tick => {
                               return formatter.format(tick);
                           }}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Amount (€)
                        </Label>
                    </YAxis>
                    {(props.options && props.options.showNettoDeb) ?
                        <Bar dataKey="netto_amount_deb" name="Netto debiteuren" fill="#3f51b5"/> :
                        ""}

                    {(props.options && props.options.showNettoOrderTerm) ?
                        <Bar dataKey="netto_amount_order_term" name="Termijnen nog te fac." fill="#00bcd4"/> :
                        ""}

                    {(props.options && props.options.showNettoOrderEnd) ?
                        <Bar dataKey="netto_amount_order_end" name="Eind. nog te fac." fill="#4caf50"/> :
                        ""}
                </ComposedChart>
            </ResponsiveContainer>
            {/* Bar chart costs */}
            <ResponsiveContainer>
                <ComposedChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={true}/>
                    <Legend/>
                    <Tooltip formatter={(value) => {
                        return formatter.format(value);
                    }
                    }
                    />
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary} padding={{right: 60, left: 60}}/>
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={tick => {
                               return formatter.format(tick);
                           }}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Amount (€)
                        </Label>
                    </YAxis>
                    {(props.options && props.options.showNettoCred) ?
                        <Bar dataKey="netto_amount_cred" name="Netto crediteuren" fill="#f44336"/> :
                        ""}

                    {(props.options && props.options.showNettoPurch) ?
                        <Bar dataKey="netto_amount_purch" name="Netto inkooporders"
                             fill={theme.palette.warning.light}/> :
                        ""}
                    {(props.options && props.options.showOtherCost) ?
                        <Bar dataKey="other_cost" name="Overige kosten" fill="#9c27b0"/> :
                        ""}
                </ComposedChart>
            </ResponsiveContainer>
            {/* Bar chart cost & income totals*/}
            <ResponsiveContainer>
                <ComposedChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={true}/>
                    <Legend/>
                    <Tooltip formatter={(value) => {
                        return formatter.format(value);
                    }
                    }
                    />
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary} padding={{right: 60, left: 60}}/>
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={tick => {
                               return formatter.format(tick);
                           }}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Amount (€)
                        </Label>
                    </YAxis>
                    <Bar dataKey="liq_pred_pos" name="Totale inkomsten" fill="#3f51b5" />
                    <Bar dataKey="liq_pred_neg" name="Totale uitgaven" fill="#f44336" />
                </ComposedChart>
            </ResponsiveContainer>


            {/* Line chart */}
            <ResponsiveContainer>
                <ComposedChart
                    data={props.data}
                    margin={{
                        top: 16,
                        right: 150,
                        bottom: 0,
                        left: 24,
                    }}
                    barCategoryGap={10}
                    barGap={0}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={true}/>
                    <Legend/>
                    <Tooltip/>
                    <XAxis dataKey="date_string" stroke={theme.palette.text.secondary} padding={{right: 60, left: 60}} />
                    <YAxis stroke={theme.palette.text.secondary}
                           tickFormatter={tick => {
                               return formatter.format(tick);
                           }}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                            margin={{right: 120}}
                        >
                            Amount (€)
                        </Label>
                    </YAxis>
                    <ReferenceLine y={props.options ? 1000 * props.options.bankLim : 0} label="Limiet" stroke="red"/>
                    <Line name="Cash" type="monotone" dataKey="liq_pred" stroke="#8884d8"/>
                </ComposedChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
