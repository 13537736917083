import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {FormControl} from '@material-ui/core';
import SubmitButton from './SubmitButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {dialogStyles} from '../styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            width: '45ch',
        },
        '& .MuiSelect-root': {
            width: '40ch',
        },
    },
    formControl: {
        marginBottom: theme.spacing(4),
    },
}));

const DialogTitle = withStyles(dialogStyles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function MailDialog(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const {onClose, open} = props;
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    const error = !(email && subject && body);

    useEffect(() => {
        if (props.poNum) {
            flaskAPI.get('/api/po-info/' + props.poNum)
                .then(response => {
                    setEmail(response.data);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
            flaskAPI.get('/api/timesheet-email-template')
                .then(response => {
                    setSubject(response.data['subject'])
                    setBody(response.data['body'])
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [props.poNum, setAuthTokens]);

    const handleClose = () => {
        onClose();
    };
    const handleSubmit = () => {
        setLoading(true);
        setSuccess(false);
        setSubmitErrorMessage("");
        flaskAPI.post('/api/mail-timesheet-report/' + props.poNum + '/' + props.dateFrom + '/' + props.dateTill,
            {
                email,
                subject,
                body,
            }
        ).then(() => {
            setLoading(false);
            setSuccess(true);
        })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
                if (error && error.response && error.response.data && error.response.data.message) {
                    setSubmitErrorMessage(error.response.data.message);
                } else {
                    setSubmitErrorMessage("Something went wrong.");
                }
            });
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle onClose={handleClose}>Mail report</DialogTitle>
            <DialogContent>
                <form className={classes.root} noValidate autoComplete="off">
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="subject"
                                label="Subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="body"
                                label="Body"
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                                variant="outlined"
                                required={true}
                                helperText="Required"
                                multiline
                                rows={11}
                                rowsMax={11}
                                fullWidth
                                autoFocus
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormHelperText error id="component-helper-text">{submitErrorMessage}</FormHelperText>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <SubmitButton disabled={error} loading={loading} onClick={handleSubmit} success={success}/>
            </DialogActions>
        </Dialog>
    );
}

MailDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};


