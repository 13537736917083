import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import FinanceChart from '../components/FinanceChart';
import FinanceTabs from '../components/FinanceTabs';
import TimeWindowSelectorFinance from '../components/TimeWindowSelectorFinance';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 701.5,
    },
}));

export default function SalesPage() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight2);
    const {setAuthTokens} = useAuth();
    const [options, setOptions] = useState()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (options && options.fromDate && options.plotInterval) {
            setData([]);
            setLoading(true);
            flaskAPI.get('/api/liq-pred',
                {params: options}).then(response => {
                setData(response.data);
                setLoading(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [options, setAuthTokens]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Grid container direction="column" spacing={3} justify="center">
                    {/* Time Window */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TimeWindowSelectorFinance onSubmit={(options) => {
                                setOptions(options);
                            }
                            }/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* Chart */}
            <Grid item xs={9}>
                <Paper className={fixedHeightPaper}>
                    <FinanceChart options={options} data={data ? data.data : []} loading={loading}/>
                </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <FinanceTabs data={data ? data : {}}/>
                </Paper>
            </Grid>
        </Grid>
    )
}
