import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {FormControl} from '@material-ui/core';
import SubmitButton from './SubmitButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import locale from 'date-fns/locale/nl'
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import {formatDate, parseHourString} from '../helpers';

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            width: '45ch',
        },
        '& .MuiSelect-root': {
            width: '40ch',
        },
    },
    formControl: {
        marginBottom: theme.spacing(4),
    },
}));

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default function BookBackDialog(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const {onClose, open} = props;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    const [hours, setHours] = useState("-2:00");
    const [hourError, setHourError] = useState(false);
    const [date, setDate] = useState(new Date());
    const [memo, setMemo] = useState("");
    const error = !(!hourError && date);


    const handleClose = () => {
        onClose();
    };
    const handleSubmit = () => {
        setLoading(true);
        setSuccess(false);
        setSubmitErrorMessage("");

        let seconds = parseHourString(hours);
        let newData = {
            pbwh_num: 40,
            pbws_num: 10,
            torh_num: props.poNum,
            uren_datum: formatDate(date),
            uren_man: seconds,
            uren_machine: seconds,
            uren_memo: memo,
            mdwa_num: 81,
            tors_num: 1,
            uren_wijze_invoer: 2
        }

        flaskAPI.post('/api/hours', newData).then(response => {
            if (response.status === 201 && Array.isArray(response.data)) {
                setLoading(false);
                setSuccess(true);
            } else {
                setLoading(false);
                setSubmitErrorMessage("Something went wrong.");
            }
        }).then(() => {
        })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
                if (error && error.response && error.response.data && error.response.data.message) {
                    setSubmitErrorMessage(error.response.data.message);
                } else {
                    setSubmitErrorMessage("Something went wrong.");
                }
            });
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle onClose={handleClose}>Book back</DialogTitle>
            <DialogContent>
                <form className={classes.root} noValidate autoComplete="off">
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="user  "
                                label="User"
                                value="CCC Mutatie"
                                variant="outlined"
                                disabled
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date (Datum)"
                                        format="dd/MM/yyyy"
                                        value={date}
                                        onChange={(newValue) => setDate(newValue)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            'tabIndex': '3'
                                        }}
                                        inputVariant="outlined"
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <div>
                                <TextField
                                    id="standard-error-helper-text"
                                    label="Hours"
                                    helperText="Notation: 5,5 or 5.5 or 5:30."
                                    autoFocus
                                    variant="outlined"
                                    error={hourError}
                                    value={hours}
                                    onChange={
                                        (event) => {
                                            let newHours = event.target.value;
                                            setHours(newHours)
                                            if (newHours.match("^-?([0-9]+):[0-5][0-9]$")) {
                                                setHourError(false);
                                            } else if (newHours.match("^-?[0-9]*[.,]?[0-9]+$")) {
                                                setHourError(false);
                                            } else {
                                                setHourError(true);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div>
                                (Result: {hourError ? "incorrect" : ((parseHourString(hours) / 3600).toFixed(2) + " hours.")})
                            </div>
                            <div>
                                (Use negative sign to book back hours.)
                            </div>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="memo"
                                label="Memo"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                variant="outlined"
                                multiline
                                rows={3}
                                rowsMax={3}
                                fullWidth
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormHelperText error id="component-helper-text">{submitErrorMessage}</FormHelperText>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <SubmitButton disabled={error} loading={loading} onClick={handleSubmit} success={success}/>
            </DialogActions>
        </Dialog>
    );
}

BookBackDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
