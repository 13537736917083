import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {tableIcons} from "../styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { CsvBuilder } from "filefy";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

const exportCsv = (columnList, initialData) => {
    const columns = columnList.filter(columnDef => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
    });

    const data = initialData.map(rowData =>
        columns.map(columnDef => {
            return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
        })
    );

    const builder = new CsvBuilder('data' + '.csv');
    builder
        .setDelimeter(',')
        .setColumns(columns.map(columnDef => columnDef.title))
        .addRows(data)
        .exportFile();
};

function BillingTable(props) {
    const {classes} = props;

    const columns = [
        {
            title: 'Client string',
            field: 'client',
        },
        {
            title: 'Date',
            field: 'event_timestamp',
            render: rowData => new Date(rowData["event_timestamp"] * 1000).toLocaleString(),
        },
        {
            title: 'Type',
            field: 'event_type',
        },
        {
            title: 'Description',
            field: 'event_desc',
        },
        {
            title: 'IP',
            field: 'ip',
        },
        {
            title: 'User id',
            field: 'user_id',
        },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Billing events
                        </Typography>
                        {/*
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<PictureAsPdfIcon/>}
                            onClick={() => props.onPdf(props.options)}
                            disabled={!props.dataRows || !(props.dataRows && props.dataRows.length)}


                        >
                            PDF
                        </Button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        */}
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<InsertDriveFileIcon/>}
                            onClick={() => exportCsv(columns, props.dataRows)}
                            disabled={!props.dataRows || !(props.dataRows && props.dataRows.length)}
                        >
                            CSV
                        </Button>
                    </div>
                }
                columns={columns}
                data={props.dataRows ? props.dataRows : []}
                /*
                actions={[
                    {
                        icon: 'Export',
                        tooltip: 'Export all',
                        onClick: (e, rowData) => {
                            let fileName = this.props.title || "row";
                            if (this.props.exportFileName) {
                                fileName =
                                    typeof this.props.exportFileName === "function"
                                        ? this.props.exportFileName()
                                        : this.props.exportFileName;
                            }

                            const builder = new CsvBuilder(fileName + ".csv");
                            builder
                                .setColumns(this.state.columns.map((columnDef) => columnDef.title))
                                .addRows(rowData.map((rowData) =>
                                    this.state.columns.map((columnDef) => rowData[columnDef.field])
                                ))
                                .exportFile();
                        }
                    }
                ]}
                 */
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                }}
            />
        </>
    );
}

export default withStyles(styles)(BillingTable);
