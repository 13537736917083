import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {secondsToHm} from '../helpers'

const styles = () => ({
    root: {
        flexGrow: 1,
    },
});

function PoInfo(props) {
    const {classes} = props;

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid container item spacing={1} xs={2} direction="column" alignItems="left">
                    <Grid item>PO number:</Grid>
                    <Grid item>Client:</Grid>
                    <Grid item>Our ref:</Grid>
                    <Grid item>Your ref:</Grid>
                </Grid>
                <Grid container item spacing={1} xs={4} direction="column" alignItems="left">
                    <Grid item>{props.data ? props.data.torh_num : "-"}</Grid>
                    <Grid item>{props.data ? props.data["pdeb_zoeknaam"] : "-"}</Grid>
                    <Grid item>{props.data ? props.data.torh_ref_onze : "-"}</Grid>
                    <Grid item>{props.data ? props.data["torh_ref_uw"] : "-"}</Grid>
                </Grid>
                <Grid container item spacing={1} xs={2} direction="column" alignItems="left">
                    <Grid item>Description:</Grid>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>VC Total:</Grid>
                    <Grid item>Booked:</Grid>
                    <Grid item>Remaining:</Grid>
                </Grid>
                <Grid container item spacing={1} xs={4} direction="column" alignItems="left">
                    <Grid item> {(props.data && props.data.torh_oms) ? props.data.torh_oms : "-"} </Grid>
                    <Grid item> {(props.data && props.data["torh_oms_2"]) ? props.data["torh_oms_2"] : "-"} </Grid>
                    <Grid item> {(props.data && props.data["torh_oms_3"]) ? props.data["torh_oms_3"] : "-"} </Grid>
                    <Grid item>{props.data ? secondsToHm(props.data["vc_seconds"]) : "-"}</Grid>
                    <Grid item>{props.data ? secondsToHm(props.data["nc_seconds"]) : "-"}</Grid>
                    <Grid item>{props.data ? secondsToHm(props.data["remaining_seconds"]) : "-"}</Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(PoInfo);
