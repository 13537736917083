import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {nl2Space} from '../../helpers';

export default function ProjectForm(props) {
    return (
        <React.Fragment>
            <Autocomplete
                id="combo-box-demo"
                value={props.value}
                onChange={props.handleChange}
                options={props.projectList}
                filterSelectedOptions
                getOptionLabel={(option) => option.torh_num + ' ' + nl2Space(option.torh_ref_onze)}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Project" variant="outlined" autoFocus/>}
                openOnFocus
                autoSelect
            />
        </React.Fragment>
    );
}
