import React, {forwardRef, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from 'moment';
import AddHourDialog from '../components/timesheet-form/AddHourDialog';
import {formatDate, parseHourString, secondsToHm} from '../helpers';
import {DateSelect, HourInput, OperationSelect, PoSelect, MemoInput} from './TimesheetControls.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <PlaylistAddIcon {...props} ref={ref}/>),
    AddLibrary: forwardRef((props, ref) => <LibraryAddIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    FileCopy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    }
});

function Timesheet(props) {
    const {classes} = props;
    const [weekNumber, setWeekNumber] = useState(moment(new Date()).isoWeek());
    const [loading, setLoading] = useState(false);
    const {setAuthTokens} = useAuth();
    const [dateString, setDateString] = useState("");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [initialFormData, setInitialFormData] = useState();
    const [me, setMe] = useState();
    const tableRef = React.createRef();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCLick = (newWeekNumber) => {
        setWeekNumber(newWeekNumber);
        props.onWeekNumberChange(newWeekNumber);
    }

    useEffect(() => {
        flaskAPI.get('/api/me')
            .then(response => {
                if (response.status === 200) {
                    setMe(response.data)
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    useEffect(() => {
        if (!me) {
            return undefined;
        }
        setLoading(true);
        let fromDate = moment().isoWeekday(1).isoWeek(weekNumber);
        let tillDate = moment().isoWeekday(7).isoWeek(weekNumber);

        if (fromDate.month() === tillDate.month()) {
            setDateString('W' + fromDate.format('WW') + ': ' + fromDate.format('DD') + ' - ' + tillDate.format('DD MMMM YYYY'));
        } else {
            setDateString('W' + fromDate.format('WW') + ': ' + fromDate.format('DD MMMM') + ' - ' + tillDate.format('DD MMMM YYYY'));
        }
        flaskAPI.get('/api/hours/joined', {
            params: {
                uren_datum_van: fromDate.format('YYYY-MM-DD'),
                uren_datum_tm: tillDate.format('YYYY-MM-DD'),
                mdwa_num_van: me["employee_id"],
                mdwa_num_tm: me["employee_id"]
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setData(response.data.reverse())
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 404) {
                    setData([])
                    setLoading(false);
                }
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [weekNumber, me, setAuthTokens]);

    const handleAdd = (newData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (newData && newData.uren_memo && newData.uren_memo != "") {
                    newData.mdwa_num = me["employee_id"];;
                    newData.tors_num = 1;
                    newData.uren_wijze_invoer = 2;
                    newData.uren_machine = newData.uren_man;
                    flaskAPI.post('/api/hours', newData).then(response => {
                        if (response.status === 201 && Array.isArray(response.data)) {
                            setData([response.data[0], ...data]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 200)
        });
    }

    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.delete('/api/hours', {data: oldData}).then(response => {
                        if (response.status === 200) {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setData([...dataDelete]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                }
            }, 200)
        });
    }
    const handleUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData && newData && newData.uren_memo && newData.uren_memo != "") {
                    newData.uren_machine = newData.uren_man;
                    flaskAPI.put('/api/hours', newData).then(response => {
                        if (response.status === 202 && Array.isArray(response.data)) {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = response.data[0];
                            setData([...dataUpdate]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 200)
        });
    }

    function customRender(value, renderType, field) {
        if (renderType === 'row') {
            return moment(value[field]).format('dddd');
        }
        if (renderType === 'group') {
            return moment(value).format('dddd');
        }
    }

    const getOpObj = ({pbwh_num, pbws_num, torb_oms}) => ({pbwh_num, pbws_num, torb_oms});
    const columns = [
        {
            title: 'Date',
            field: 'uren_datum',
            type: 'date',
            editable: () => tableRef.current && tableRef.current.state.showAddRow,
            editComponent: props => <DateSelect value={props.value}
                                                onChange={(newValue) => props.onChange(formatDate(newValue))}/>,
            initialEditValue: new Date(),
        },
        {
            title: 'Day',
            field: 'uren_datum',
            render: (value, renderType) => customRender(value, renderType, 'uren_datum'),
            editComponent: props => (tableRef.current && tableRef.current.state.showAddRow) ? <></> : customRender(props.value, 'row', 'uren_datum')
        },
        {
            title: 'PO Number',
            field: 'torh_num',
            editComponent: props => <PoSelect value={props.rowData}
                                              onChange={(event, newValue) => props.onChange(newValue.torh_num)}/>
        },
        {
            title: 'Description',
            field: 'torh_ref_onze',
            editComponent: () => <></>,
        },
        {
            title: 'Client',
            field: 'pdeb_bnaam',
            editComponent: () => <></>,
        },
        {
            title: 'Operation',
            field: 'torb_oms',
            editComponent: props => <OperationSelect poNum={props.rowData.torh_num} value={getOpObj(props.rowData)}
                                                     onChange={(event, newValue) => {
                                                         // Can't use spread operator because rowData is read-only :-(
                                                         for (let x in getOpObj(props.rowData)) props.rowData[x] = newValue[x];
                                                     }
                                                     }/>
        },
       {
            title: 'Memo',
            field: 'uren_memo',
            editComponent: props => <MemoInput value={props.value}
                                               onChange={(newVal) => props.onChange(newVal)}/>,
        },
        {
            title: 'Hours',
            field: 'uren_man',
            render: rowData => secondsToHm(rowData.uren_man),
            type: 'time',
            editComponent: props => <HourInput value={secondsToHm(props.value)}
                                               onChange={(newVal) => props.onChange(parseHourString(newVal))}/>,
            initialEditValue: 3600,
        },
    ];
    return (
        <>
            <AddHourDialog open={open} onClose={handleClose} handleAdd={handleAdd}/>
            <MaterialTable
                localization={{
                    header: {
                        actions: ''
                    }
                }}
                icons={tableIcons}
                isLoading={loading}
                title={
                    <div className={classes.tableTitle}>
                        <ButtonGroup size="small" color="primary">
                            <Button onClick={() => handleCLick(weekNumber - 1)}><KeyboardArrowLeft/></Button>
                            <Button onClick={() => handleCLick(weekNumber + 1)}><KeyboardArrowRight/></Button>
                        </ButtonGroup>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            {dateString}
                        </Typography>
                    </div>
                }
                columns={columns}
                data={data}
                initialFormData={initialFormData}
                options={{
                    paging: false,
                    search: false,
                    sorting: false,
                    grouping: true,
                    addRowPosition: "first",
                }}
                tableRef={tableRef}
                actions={[
                    {
                        icon: tableIcons.FileCopy,
                        tooltip: 'Duplicate',
                        onClick: (event, rowData) => {
                            const materialTable = tableRef.current;
                            //Duplicate but set date to today
                            let copy = {};
                            Object.assign(copy, rowData);
                            copy["uren_datum"] = formatDate(new Date());

                            setInitialFormData(copy);

                            materialTable.dataManager.changeRowEditing();
                            materialTable.setState({
                                ...materialTable.dataManager.getRenderState(),
                                showAddRow: true,
                            });
                        }
                    }
                ]}
                editable={{
                    isEditable: rowData => rowData["uren_status"] === 0,
                    isDeletable: rowData => rowData["uren_status"] === 0,
                    onRowAdd: handleAdd,
                    onRowDelete: handleDelete,
                    onRowUpdate: handleUpdate,
                }}
            />
        </>
    );
}

export default withStyles(styles)(Timesheet);
