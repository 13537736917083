import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'flex',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        justifyContent: 'flex-end',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function SubmitButton(props) {
    const classes = useStyles();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: props.success,
    });

    return (
        <div className={classes.wrapper}>
            <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                disabled={props.loading || props.disabled}
                onClick={props.onClick}
            >
                Submit
            </Button>
            {props.loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
    );
}
