import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import {formatDate, secondsToHm, truncateText} from '../helpers'
import {Link, useHistory} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PoInfo from './PoInfo';
import TimeWindowButtons from './Months';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailDialog from './MailDialog';
import BookBackDialog from './BookBackDialog';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import PoHoursTable from "./PoHoursTable";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 619.5,
    },
}));


function HourRow(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <>
                <TableRow key={row["id"]} className={classes.root}>
                    <TableCell>{row["uren_datum"].split("T")[0]}</TableCell>
                    <TableCell>{row["mdwa_zoeknaam"]}</TableCell>
                    <TableCell>{row["torb_oms"]}</TableCell>
                    <TableCell>{secondsToHm(row["uren_man"])}</TableCell>
                    <TableCell>
                        {truncateText(row["uren_memo"], 26, true)}
                        {(row["uren_memo"] && row["uren_memo"].length > 26) ?
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                            : ""}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                        <Collapse in={open && row["uren_memo"] && row["uren_memo"].length > 26} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {row["uren_memo"].split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        </React.Fragment>
    );
}

export default function PoPage({match}) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [poInfoData, setPoInfoData] = useState([]);
    const [timewindow, setTimeWindow] = useState({fromDate: "2010-01-01", tillDate: "2099-01-01",});
    const [mailOpen, setMailOpen] = useState(false);
    const [bookBackOpen, setBookBackOpen] = useState(false);

    const dateString = (val) => {
        const date = new Date(val);
        if (date.getFullYear() <= 2010) {
            return "All";
        }
        return date.toLocaleString('en-us', {month: 'long'});
    }

    const firstDateLabel = (i) => {
        let x = new Date();
        if (i < 0) {
            x.setFullYear(2010)
            x.setDate(1);
            x.setMonth(0);
            return formatDate(x);
        }
        x.setDate(1);
        x.setMonth(x.getMonth() - i);
        return formatDate(x);
    }
    const lastDateLabel = (i) => {
        let x = new Date();
        if (i < 0) {
            x.setFullYear(2099)
            x.setDate(1);
            x.setMonth(0);
            return formatDate(x);
        }
        x.setMonth(x.getMonth() - i + 1);
        x.setDate(0);
        return formatDate(x);
    }

    const hourTotal = data ? data.reduce((totalHours, hour) => totalHours + hour.uren_man, 0) : "";
    const priceTotal = data ? data.reduce((totalPrice, hour) => totalPrice + hour.uren_man / 3600 * hour["torb_tarief"], 0) : "";
    const {
        params: {poNum},
    } = match;

    useEffect(() => {
        setData([]);
        if (poNum && timewindow) {
            flaskAPI.get('/api/hours/joined', {
                params: {
                    'torh_num_van': poNum,
                    'torh_num_tm': poNum,
                    'uren_datum_van': timewindow.fromDate,
                    'uren_datum_tm': timewindow.tillDate
                }
            })
                .then(response => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [poNum, timewindow, setAuthTokens]);

    useEffect(() => {
        setPoInfoData([]);
        if (poNum) {
            flaskAPI.get('/api/pos/joined/' + poNum)
                .then(response => {
                    setPoInfoData(response.data);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [poNum, setAuthTokens]);

    const history = useHistory();
    const handleBack = () => {
        let path = '/hours';
        history.push(path);
    }

    return (
        <>
            <MailDialog
                open={mailOpen}
                onClose={() => setMailOpen(false)}
                poNum={poNum}
                dateFrom={timewindow.fromDate}
                dateTill={timewindow.tillDate}
            />
            <BookBackDialog
                open={bookBackOpen}
                onClose={() => setBookBackOpen(false)}
                poNum={poNum}
            />
            <Grid container direction="column" spacing={3} justify="center">
                <Grid item justify="left">
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleBack}>
                        <ArrowBackIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <PoInfo
                            data={poInfoData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <TimeWindowButtons
                            onClick={(i) => setTimeWindow({fromDate: firstDateLabel(i), tillDate: lastDateLabel(i)})}
                            onMail={() => setMailOpen(true)}
                            onBookBack={() => setBookBackOpen(true)}
                            onPdf={() => {
                                flaskAPI.get('/api/reports/timesheet/' + poNum + '/' + timewindow.fromDate + '/' + timewindow.tillDate)
                                    .then((response) => {
                                            const file = new Blob([response.data], {type: "application/pdf"});
                                            //Build a URL from the file
                                            const fileURL = URL.createObjectURL(file);
                                            //Open the URL on new Window
                                            const pdfWindow = window.open();
                                            pdfWindow.location.href = fileURL;
                                        }
                                    )
                                    .catch(error => {
                                        if (error && error.response && error.response.status === 401) {
                                            setAuthTokens();
                                        }
                                    })
                            }
                            }
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                        <PoHoursTable
                            title={"Hour logs - " + dateString(timewindow.fromDate)}
                            loading={loading}
                            data={data}
                        />
                        {/*
                        <Title>Hour logs - {dateString(timewindow.fromDate)} {loading ?
                            <CircularProgress color="secondary" size={22}/> : ""}</Title>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Employee</TableCell>
                                    <TableCell>Operation</TableCell>
                                    <TableCell>Hours</TableCell>
                                    <TableCell>Memo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data ? data.map(row => (
                                    <HourRow row={row}/>
                                )) : ""}
                                <TableRow>
                                    <TableCell colSpan={3} align="right">Total</TableCell>
                                    <TableCell>{secondsToHm(hourTotal)}</TableCell>
                                    <TableCell/>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} align="right">Price</TableCell>
                                    <TableCell>{"€" + priceTotal}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableBody>
                        </Table>
                        */}
                        <Link to="/hours">Back to overview</Link>
                </Grid>
            </Grid>
        </>
    );
};
