import React, {useEffect, useState} from 'react';
import {MenuItem, TextField,} from "@material-ui/core";
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";

function EmployeeSelect(props) {
    const [employees, setEmployees] = useState([]);
    const {setAuthTokens} = useAuth();

    useEffect(() => {
        flaskAPI.get('/api/employees')
            .then(response => {
                if (response.status === 200) {
                    setEmployees(response.data);
                }
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    return (
        <TextField
            select
            label={'label' in props ? props.label : ""}
            value={props.value}
            onChange={props.onChange}
            variant={'variant' in props ? props.variant : "standard"}
            required={'required' in props ? props.required : false}
            helperText={'helperText' in props ? props.helperText : ""}
        >
            <MenuItem value={null}>_</MenuItem>
            {employees && employees.map((row) => (
                <MenuItem value={row.mdwa_num}>{row.mdwa_num + " - " + row["mdwa_naam"]}</MenuItem>
            ))}
        </TextField>
    );
}

function RoleSelect(props) {
    return (
        <TextField
            select
            label={'label' in props ? props.label : ""}
            value={props.value}
            onChange={props.onChange}
            variant={'variant' in props ? props.variant : "standard"}
            required={'required' in props ? props.required : false}
            helperText={'helperText' in props ? props.helperText : ""}
        >
            <MenuItem value='employee'>employee</MenuItem>
            <MenuItem value='admin'>admin</MenuItem>
            <MenuItem value='device'>device</MenuItem>
        </TextField>
    );
}

function PasswordField(props) {
    return (
        <TextField
            id="password"
            label={'label' in props ? props.label : ""}
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                autocomplete: 'new-password',
                form: {
                    autocomplete: 'off',
                },
            }}
            variant={'variant' in props ? props.variant : "standard"}
            required={'required' in props ? props.required : false}
            helperText={'helperText' in props ? props.helperText : ""}
        />
    );
}

export {EmployeeSelect, RoleSelect, PasswordField}
