import {config} from './Constants'
import axios from 'axios'
import Cookie from 'js-cookie'

export const flaskAPI = axios.create();

flaskAPI.interceptors.request.use(function (config) {
    let token = getAccessToken();
    config.headers.Authorization = 'Bearer ' + token;
    return config;
}, function (error) {
    return Promise.reject(error);
});

function getAccessToken() {
    return Cookie.get('token');
}

function setAccessToken(token) {
    Cookie.set('token', token,
        {expires: 14, secure: config.useSecureCookie,} // 14 days
    );
}

async function refreshAccessToken() {
    try {
        const response = await flaskAPI.get("/api/refresh");
        const data = await response.data;
        return data['access_token'];
    } catch (error) {
        console.log("error", error);
    }
}


flaskAPI.interceptors.response.use(async (response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    const errorStatus = error.response.status;
    const errorType = error.response.data.error;
    if (
        errorStatus === 401 &&
        errorType === 'ExpiredAccessError' &&
        !originalRequest._retry &&
        !originalRequest.url.includes('/api/refresh')
    ) {
        let token = await refreshAccessToken();
        setAccessToken(token);

        originalRequest._retry = true;
        originalRequest.headers.authorization = undefined;

        return flaskAPI(originalRequest);
    }
    return Promise.reject(error);
});

