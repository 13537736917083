import React, {forwardRef, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

function IKVKTable() {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [hidden, setHidden] = useState();
    const [filteredData, setFilteredData] = useState();

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/purchase/ikvsvk')
            .then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });

        flaskAPI.get('/api/purchase/hidden')
            .then(response => {
                if (response.status === 200) {
                    setHidden(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    useEffect(() => {
        setFilteredData(
            data ? hidden ? data.filter(row => {
                for (let i = 0; i < hidden.length; i++) {
                    if (row["vorh_num"] === hidden[i]["vorh_num"] && row["ikwh_num"] === hidden[i]["ikwh_num"]) {
                        return false;
                    }
                }
                return true;
            }) : data : []
        );
    }, [data, hidden]);


    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.post('/api/purchase/hide', oldData).then(response => {
                        if (response.status === 201) {
                            const dataDelete = [...filteredData];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setFilteredData([...dataDelete]);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 200)
        });
    }


    const columns = [
        {
            title: 'VK',
            field: 'vorh_num',
        },
        {
            title: 'Omschrijving',
            field: 'vorh_oms',
        },
        {
            title: 'Datum planning',
            field: 'vorb_dat_planning',
            type: 'date',
        },
        {
            title: 'Inkoop ord.',
            field: 'ikwh_num',
        },
        {
            title: 'Datum levering',
            field: 'ikwh_dat_lev',
            type: 'date',
        },
        {
            title: 'Dagen tussen planning en levering',
            field: 'date_diff',
            numeric: true,
            customSort: (a, b) => a["date_diff"] < b["date_diff"]
        },
    ];
    return (
        <MaterialTable
            components={{Container: props => <Paper {...props} elevation={0}/>}}
            icons={tableIcons}
            isLoading={loading}
            title=''
            columns={columns}
            data={filteredData}
            editable={{
                onRowDelete: handleDelete,
            }}
            options={{
                pageSize: 10,
                paging: true,
                search: true,
                sorting: true,
            }}
        />
    );
}

export default withStyles(styles)(IKVKTable);
