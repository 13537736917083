import React from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import HoursOverviewTable from '../components/HoursOverviewTable';
import {withRouter} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 619.5,
    },
}));

function ReportingPage(props) {
    const classes = useStyles();
    const handleRowClick = (poNum) => {
        props.history.push('hours/' + poNum);
    }

    return (
        <Paper className={classes.paper}>
            <HoursOverviewTable handleClick={handleRowClick}/>
        </Paper>
    )
}

const HoursReportingPage = withRouter(ReportingPage);
export {HoursReportingPage};
