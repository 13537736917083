import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import CircularProgress from '@material-ui/core/CircularProgress';
import {formatDate} from '../helpers';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

export default function ProdSum(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Title>Productive hours {props.loading ? (
                <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {((props.data && props.data["total_prod"]) ? props.data["total_prod"] : 0).toFixed(2)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                {(props.fromDate && props.tillDate) ? (<>
                    from {formatDate(props.fromDate)} to {formatDate(props.tillDate)}
                </>) : (<br/>)}
            </Typography>

            <Title>Unproductive hours {props.loading ? (
                <CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {((props.data && props.data["total_unprod"]) ? props.data["total_unprod"] : 0).toFixed(2)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                {(props.fromDate && props.tillDate) ? (<>
                    from {formatDate(props.fromDate)} to {formatDate(props.tillDate)}
                </>) : (<br/>)}
            </Typography>

            <Title>Total hours {props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Typography component="p" variant="h4">
                {((props.data && props.data["total_prod"] && props.data["total_unprod"]) ? (props.data["total_prod"] + props.data["total_unprod"]) : 0).toFixed(2)}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                {(props.fromDate && props.tillDate) ? (<>
                    from {formatDate(props.fromDate)} to {formatDate(props.tillDate)}
                </>) : (<br/>)}
            </Typography>
        </React.Fragment>
    );
}
