import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import ProdChart from '../components/ProdChart';
import ProdSum from '../components/ProdSum';
import TimeWindowSelectorProd from '../components/TimeWindowSelectorProd';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {formatDate} from '../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));

export default function ProductivityPage() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight2);
    const [fromDate, setFromDate] = useState();
    const [tillDate, setTillDate] = useState();
    const [interval, setInterval] = useState()
    const [relative, setRelative] = useState()
    const [plotType, setPlotType] = useState()

    const {setAuthTokens} = useAuth();
    const [plotData, setPlotData] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (fromDate && interval) {
            setPlotData([]);
            setLoading(true);
            flaskAPI.get('/api/hours/joined/amount', {
                params: {
                    'interval': interval,
                    'date_from': formatDate(fromDate),
                    'date_till': formatDate(tillDate)
                }
            })
                .then(response => {
                    setPlotData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [interval, fromDate, tillDate, setAuthTokens]);

    /*useEffect(() => {
      setTableError(false);
      setHelperText(' ');
      if (fromDate && tillDate) {
        // To calculate the time difference of two dates
        let differenceInTime = tillDate.getTime() - fromDate.getTime();
        // To calculate the no. of days between two dates
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);
        if (differenceInDays > 45) {
          setTableError(true);
          setSalesList();
          setHelperText('Can only show individual orders for a period smaller than 45 days.')
        } else {
          flaskAPI.get('/api/sales/joined/list', {params: {'date_from': formatDate(fromDate), 'date_till': formatDate(tillDate) }}) // TODO props.date should already be in proper format
            .then(response => {
              setSalesList(response.data);
            })
            .catch(error => {
              if (error && error.response && error.response.status === 401) {
                setAuthTokens();
              }
            });
        }
      }
    }, [salesInterval, fromDate, tillDate, setAuthTokens]);
    */

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Grid container direction="column" spacing={3} justify="center">
                    {/* Time Window */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TimeWindowSelectorProd
                                onDateChanged={(plotType, interval, relative, fromDate, tillDate) => {
                                    setPlotType(plotType);
                                    setInterval(interval);
                                    setRelative(relative);
                                    setFromDate(fromDate);
                                    setTillDate(tillDate);
                                }
                                }/>
                        </Paper>
                    </Grid>
                    {/* Sales sum */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <ProdSum data={plotData}/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* Chart */}
            <Grid item xs={9}>
                <Paper className={fixedHeightPaper}>
                    <ProdChart plotType={plotType} loading={loading} data={plotData && plotData["data"]}
                               relative={relative}/>
                </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={12}>
                {/*
        <Paper className={classes.paper}>
          <SalesTable data={salesList} error={tableError} helperText={helperText} />
        </Paper>
        */}
            </Grid>
        </Grid>
    )
}
