import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const Combo = (props) => {
    return (
        <FormControl>
            <InputLabel>{props.label}</InputLabel>
            <Select name={props.name} value={props.value} onChange={props.onChange} style={{width: 120}}>
                {props.options.map((e, i) =>
                    <MenuItem value={i}>{e}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default function TimeWindowSelector(props) {
    const {setAuthTokens} = useAuth();
    const [saldo, setSaldo] = useState(0);
    const [fromDate, setFromDate] = useState()
    const [tillDate, setTillDate] = useState()
    const [state, setState] = useState({
        plotInterval: 'week',
        showNettoDeb: true,
        showNettoCred: true,
        showNettoOrderTerm: true,
        showNettoOrderEnd: true,
        showNettoPurch: true,
        showOtherCost: true,
        bankLim: -250,
        weekCost: 0,
        monthCost: 125,
        quarterCost: 0,
        monthCostOffset: 23,
        quarterCostOffset: 0,
    });

    const now = moment();
    const nextMonth = moment(now).add(1, 'month');

    useEffect(() => {
        if (!saldo) {
            flaskAPI.get('/api/ledgers/cumsum?date_from=' + now.format('YYYY-MM-01') + '&date_till=' + nextMonth.format('YYYY-MM-01') + '&ledger_list[]=1225').then(response => {
                if (Array.isArray(response.data) && "1225" in response.data[0]) {
                    setSaldo((response.data[response.data.length - 1]["1225"] / 1000).toFixed(0));
                }
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [nextMonth, now, saldo, setAuthTokens]);

    useEffect(() => {
        if (!fromDate && !tillDate) {
            flaskAPI.get('/api/liq-pred/timewindow').then(response => {
                if ("from_date" in response.data && "till_date" in response.data) {
                    setTillDate(new Date(response.data.till_date));
                    setFromDate(new Date(response.data.from_date));
                }
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [setAuthTokens]);

    const updateConfig = (newData) => {
        setTimeout(() => {
            flaskAPI.put('/api/config/1', {"liq_pred": newData}).then(response => {
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        }, 400)
    }

    useEffect(() => {
        flaskAPI.get('/api/config/1').then(response => {
            if("liq_pred" in response.data) {
                let newState = response.data["liq_pred"];
                setState({...state, ...newState});
            }
        })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);


    const financeTypeError = [state.showNettoDeb, state.showNettoCred, state.showNettoOrderTerm, state.showNettoOrderEnd, state.showNettoPurch, state.showOtherCost].filter((v) => v).length < 1;
    const [fromDateError, setFromDateError] = useState(false);
    const [tillDateError, setTillDateError] = useState(false);
    const error = financeTypeError || fromDateError || tillDateError;

    const classes = useStyles();

    const handleFromDateChange = (date) => {
        if (isValidDate(date)) {
            setFromDateError(false);
            setFromDate(date);
        } else {
            setFromDateError(true);
        }
    };
    const handleTillDateChange = (date) => {
        if (isValidDate(date)) {
            setTillDateError(false);
            setTillDate(date);
        } else {
            setTillDateError(true);
        }
    };

    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isValidDate(fromDate) && isValidDate(tillDate)) {
            props.onSubmit({...state, fromDate, tillDate, saldo});
        }
    }
    const handleCheckChange = (event) => {
        let newState = {...state, [event.target.name]: event.target.checked}
        setState(newState);
        updateConfig(newState)
    };

    const handleChange = (event) => {
        let newState = {...state, [event.target.name]: event.target.value}
        setState(newState);
        updateConfig(newState)
    };

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" error={fromDateError || tillDateError}
                             className={classes.formControl}>
                    <FormLabel component="legend">Period</FormLabel>
                    <FormGroup>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                error={fromDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="fromDate"
                                label="Date from"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                error={tillDateError}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="tillDate"
                                label="Date till"
                                value={tillDate}
                                onChange={handleTillDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDateMessage="Date should not be in future"
                            />
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Interval</FormLabel>
                    <FormGroup>
                        <RadioGroup aria-label="plot interval" name="plotInterval" value={state.plotInterval}
                                    onChange={handleChange}>
                            <FormControlLabel value="week" control={<Radio/>} label="Week"/>
                            <FormControlLabel value="month" control={<Radio/>} label="Month"/>
                            <FormControlLabel value="quarter" control={<Radio/>} label="Quarter"/>
                            <FormControlLabel value="year" control={<Radio/>} label="Year"/>
                        </RadioGroup>
                    </FormGroup>
                </FormControl>

                <FormControl required component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Options</FormLabel>
                    <FormGroup>
                        <TextField id="standard-basic" value={saldo} name="saldo"
                                   onChange={(e) => setSaldo(e.target.value)} label="Saldo (x €1k)"/>
                        <TextField id="standard-basic" value={state.bankLim} name="bankLim" onChange={handleChange}
                                   label="Rekn. courant lim. (x €1k)"/>

                        <FormLabel required={false} style={{marginTop: 10}} component="legend">Other costs</FormLabel>
                        <Grid container spacing={1}>
                            <Grid xs={7} item><TextField value={state.weekCost} name="weekCost" onChange={handleChange}
                                                         label="Week cost(x €1k)"/></Grid>
                            <Grid xs={5} item>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid xs={7} item><TextField value={state.monthCost} name="monthCost"
                                                         onChange={handleChange} label="Month cost (x €1k)"/></Grid>
                            <Grid xs={5} item>
                                <Combo options={Array.from({length: 31}, (_, i) => i + 1)} value={state.monthCostOffset}
                                       name="monthCostOffset" onChange={handleChange} label="At day"/></Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid xs={7} item><TextField value={state.quarterCost} name="quarterCost"
                                                         onChange={handleChange} label="Quarter cost(x €1k)"/></Grid>
                            <Grid xs={5} item>
                                <Combo options={Array.from({length: 90}, (_, i) => i + 1)}
                                       value={state.quarterCostOffset} name="quarterCostOffset" onChange={handleChange}
                                       label="At day"/></Grid>
                        </Grid>
                    </FormGroup>
                </FormControl>

                <FormControl required error={financeTypeError} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Data type</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={state.showNettoDeb} onChange={handleCheckChange}
                                               name="showNettoDeb"/>}
                            label="Debiteuren inc BTW(€)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.showNettoCred} onChange={handleCheckChange}
                                               name="showNettoCred"/>}
                            label="Crediteuren inc BTW(€)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.showNettoOrderTerm} onChange={handleCheckChange}
                                               name="showNettoOrderTerm"/>}
                            label="Termijnen nog te fac. ex BTW(€)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.showNettoOrderEnd} onChange={handleCheckChange}
                                               name="showNettoOrderEnd"/>}
                            label="Eindfact. nog te fac. ex BTW(€)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.showNettoPurch} onChange={handleCheckChange}
                                               name="showNettoPurch"/>}
                            label="Cred. nog te fac. ex BTW(€)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.showOtherCost} onChange={handleCheckChange}
                                               name="showOtherCost"/>}
                            label="Other costs (€)"
                        />
                    </FormGroup>
                </FormControl>
                <FormGroup>
                    <Button disabled={error} type="submit" variant="outlined" color="primary"
                            className={classes.button}>
                        Show me the sales
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}

