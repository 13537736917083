import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../components/Title';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import CircularProgress from '@material-ui/core/CircularProgress';
import {secondsToHm} from '../helpers';

function createHeader() {
    const columns = ['Medewerker nummer', 'Naam', 'TVT stand', 'Vakantieuren per jaar', 'Vakantie uren resterend']
    return (
        <>
            {
                columns.map((column) =>
                    <TableCell align="center" padding="none">{column}</TableCell>
                )
            }
        </>
    );
}

function createCell(data, color) {
    return (
        <TableCell align="center" padding="none"
                   style={{minWidth: 40, maxWidth: 40, margin: 0, backgroundColor: color}}>
            {data}
        </TableCell>
    );
}

export default function TvtHolidayOverview() {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/timesheet/employee-tvt-holiday-overview')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    return (
        <React.Fragment>
            <Title>TVT / Holiday {loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {createHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data ? data.map((row) => (
                        <TableRow>
                            {createCell(row["mdwa_num"], 'transparent')}
                            {createCell(row["mdwa_naam"], 'transparent')}
                            {createCell(secondsToHm(row["mdwa_tvt_stand"]), (Number(row["mdwa_tvt_stand"]) <= (-18 * 3600) ? '#f44336' : 'transparent'))}
                            {createCell(row["mdwa_vakantieuren_pj"], 'transparent')}
                            {createCell(secondsToHm(row["mdwa_vakantieuren_rest"]), Number(row["mdwa_vakantieuren_rest"]) > (Number(row["mdwa_vakantieuren_pj"]) * 3600 * 1.5) ? '#f44336' : 'transparent')}
                        </TableRow>
                    )) : (<> </>)}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
